import React, { useState } from 'react'
import { Form, Input, Button, Space } from 'antd'

const initialSearch = { name: '' }

const AgencySearch = ({ meta, handleSearch }) => {
  const [search, setSearch] = useState(initialSearch)
  const handleChange = (e) =>
    setSearch({ ...search, [e.target.name]: e.target.value })

  const handleSubmit = () => {
    // console.log(search)
    handleSearch({ ...search, currentPage: 1 })
  }

  const handleReset = () => {
    setSearch(initialSearch)
    handleSearch({ ...initialSearch, currentPage: 1 })
  }

  return (
    <Form layout="inline" style={{ marginBottom: 16, float: 'right' }}>
      <Form.Item>
        <Input
          name="name"
          value={search.name}
          onChange={handleChange}
          style={{ width: 160 }}
          placeholder="대리점이름"
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button onClick={handleReset}>리셋</Button>
          <Button type="primary" onClick={handleSubmit}>
            검색
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default AgencySearch
