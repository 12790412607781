import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'antd'
import {
  Form,
  Input,
  Select,
  Switch,
  Row,
  Col,
  Divider,
  DatePicker,
  Checkbox,
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
// import MultiImageInput from './MultiImageInput'
// import PopuarMenuInput from './PopuarMenuInput'
// import 'moment/locale/ko-kr'
// import moment from 'moment'
import 'moment/locale/ko'
// import locale from 'antd/es/date-picker/locale/ko_KR'

const initialStore = {
  ceoId: null,
  name: '',
  mobile: '',
  active: 'Y',
}

const StoreForm = (props) => {
  const { isAdmin, requesting, errors, store, removeError, ceoId } = props
  const {
    open,
    handleClose,
    handleExit,
    handleCreate,
    handleUpdate,
    handleDelete,
  } = props
  const [inputs, setInputs] = useState(initialStore)
  const isNew = !store

  useEffect(() => {
    store && store.id && setInputs(store)
    return () => {
      setInputs(initialStore)
    }
  }, [store])

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
    removeErrorWithName(errors, e.target.name)
  }
  const handleActiveChane = (value) => {
    setInputs({ ...inputs, active: value ? 'Y' : 'N' })
    removeErrorWithName(errors, 'active')
  }
  const removeErrorWithName = (errors, name) => {
    errors && errors[name] && removeError({ name })
  }

  const handleOnCreate = () => handleCreate({ ...inputs, ceoId: ceoId })
  const handleOnUpdate = () => handleUpdate(inputs)
  const handleDeleteConfirm = () => {
    Modal.confirm({
      title: '정말로 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      content: '삭제하면 데이터를 복구할수 없습니다.',
      okText: '삭제',
      cancelText: '취소',
      onOk() {
        handleDelete(inputs)
      },
    })
  }

  const createFooter = [
    <Button key="cancle" onClick={handleClose} disabled={requesting}>
      취소
    </Button>,
    <Button
      key="create"
      type="primary"
      onClick={handleOnCreate}
      disabled={requesting}
    >
      등록
    </Button>,
  ]

  const updateFooter = [
    <Button
      key="delete"
      type="primary"
      danger
      onClick={handleDeleteConfirm}
      disabled={requesting || !isAdmin}
    >
      삭제
    </Button>,
    <Button key="cancle" onClick={handleClose} disabled={requesting}>
      취소
    </Button>,
    <Button
      key="update"
      type="primary"
      onClick={handleOnUpdate}
      disabled={requesting}
    >
      저장
    </Button>,
  ]

  const layout = {
    layout: 'vertical',
  }

  return (
    <Modal
      title={!isNew ? '매장 수정' : '매장 등록'}
      visible={open}
      afterClose={handleExit}
      onCancel={handleClose}
      footer={!isNew ? updateFooter : createFooter}
      maskClosable={false}
    >
      <Form {...layout}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="매장명"
              validateStatus={errors && errors.name && 'error'}
              help={errors && errors.name && errors.name[0]}
            >
              <Input
                name="name"
                value={inputs.name}
                onChange={handleInputChange}
                disabled={requesting}
                placeholder="맛나치킨"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="휴대전화번호"
              validateStatus={errors && errors.mobile && 'error'}
              help={errors && errors.mobile && errors.mobile[0]}
            >
              <Input
                name="mobile"
                value={inputs.mobile}
                onChange={handleInputChange}
                disabled={requesting}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="발송상태"
              validateStatus={errors && errors.active && 'error'}
              help={errors && errors.active && errors.active[0]}
            >
              <Switch
                name="active"
                checked={inputs.active === 'Y'}
                onChange={handleActiveChane}
                disabled={requesting}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
export default StoreForm
