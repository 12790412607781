import request from 'lib/request'

const ceosApi = {
  fetch: (meta) => request.get('/api/ceos', meta),
  fetchOne: (ceo) => request.get(`/api/ceos/${ceo.id}`),
  create: (ceo) => request.post('/api/ceos', ceo),
  update: (ceo) => request.put(`/api/ceos/${ceo.id}`, ceo),
  delete: (ceo) => request.del(`/api/ceos/${ceo.id}`),
  // 적립금 처리
  fetchCashes: (ceo) => request.get(`/api/ceos/${ceo.id}/cash`),
  updateCash: (ceo, cash, title) =>
    request.put(`/api/ceos/${ceo.id}/cash`, {
      cash: cash,
      title: title,
    }),
  //
}

export default ceosApi
