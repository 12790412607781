import React from 'react'
import { useSelector } from 'react-redux'

import Page from 'components/Common/Page'
import ReviewList from 'modules/reviews/components/ReviewList'

const ReviewsPage = (props) => {
  const { history, location } = props

  const isAdmin = useSelector(
    (store) => store.account.user && store.account.user.role === 'admin'
  )

  return (
    <Page>
      <ReviewList history={history} location={location} isAdmin={isAdmin} />
    </Page>
  )
}

export default ReviewsPage
