import { takeEvery, all } from 'redux-saga/effects'
import { unSetToken } from './store'
// import { removeToken } from 'lib/token'
import { removeToken, removeRemember } from 'lib/token'

import { actionTypes as accountTypes } from 'modules/core/account/store'
import { setAuthHeader } from 'lib/request/axios'

function resetToken() {
  unSetToken()
  removeToken()
  removeRemember()
  setAuthHeader(undefined)
}

export default function* clientSaga() {
  yield all([takeEvery(accountTypes.LOGOUT, resetToken)])
}
