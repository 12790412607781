import { all } from 'redux-saga/effects'
import appSagas from './app/sagas'
import accountSagas from './account/sagas'
import agenciesSagas from './agencies/sagas'
import ceosSagas from './ceos/sagas'

import app from './app/store'
import account from './account/store'
import agencies from './agencies/store'
import ceos from './ceos/store'

const reducers = {
  app,
  account,
  agencies,
  ceos,
}

function* sagas() {
  yield all([appSagas(), accountSagas(), agenciesSagas(), ceosSagas()])
}

export { reducers, sagas }
