import { combineReducers } from 'redux'
import { reducers as coreReducers } from 'modules/core'
import stores from 'modules/stores/store'

const rootReducer = combineReducers({
  ...coreReducers,
  stores,
})

export default rootReducer
