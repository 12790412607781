import { makeAsyncActionsTypes, makeActionCreator, makeAsyncActionCreator } from 'lib/saga/reduxActionUtils'

const prefix = '@CORE/ACCOUNT'

export const actionTypes = {
  TOKEN_LOGIN: makeAsyncActionsTypes(prefix, 'TOKEN_LOGIN'),
  LOGIN: makeAsyncActionsTypes(prefix, 'LOGIN'),
  JOIN: makeAsyncActionsTypes(prefix, 'JOIN'),
  LOGOUT: `${prefix}LOGOUT`,
  FETCH_USER: makeAsyncActionsTypes(prefix, 'FETCH_USER'),
  SET_USER: makeActionCreator(prefix, 'SET_USER'),
}

export const tokenLogin = makeAsyncActionCreator(actionTypes.TOKEN_LOGIN)
export const login = makeAsyncActionCreator(actionTypes.LOGIN)
export const join = makeAsyncActionCreator(actionTypes.JOIN)
export const logout = makeActionCreator(actionTypes.LOGOUT)
export const fetchUser = makeAsyncActionCreator(actionTypes.FETCH_USER)
export const setUser = makeAsyncActionCreator(actionTypes.SET_USER)

const initialState = {
  loading: false,
  user: null,
  error: null,
  login: {
    loading: false,
    error: null,
  },
  join: {
    loading: false,
    error: null,
  },
}

const reducer = function (state = initialState, action) {
  // console.log(action)
  switch (action.type) {
    //  토큰로그인
    case actionTypes.TOKEN_LOGIN.INDEX:
    case actionTypes.TOKEN_LOGIN.REQUEST:
      return {
        ...state,
        login: { ...state.login, loading: true, error: null },
      }
    case actionTypes.TOKEN_LOGIN.SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        login: { ...state.login, loading: false, error: null },
      }
    case actionTypes.TOKEN_LOGIN.FAIL:
      return {
        ...state,
        user: action.payload.user,
        login: { ...state.login, loading: false, error: action.payload.error },
      }

    // 로그인
    case actionTypes.LOGIN.INDEX:
    case actionTypes.LOGIN.REQUEST:
      return {
        ...state,
        login: { ...state.login, loading: true, error: null },
      }
    case actionTypes.LOGIN.SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        login: { ...state.login, loading: false, error: null },
      }
    case actionTypes.LOGIN.FAIL:
      return {
        ...state,
        user: action.payload.user,
        login: { ...state.login, loading: false, error: action.payload.error },
      }

    // 회원가입
    case actionTypes.JOIN.INDEX:
    case actionTypes.JOIN.REQUEST:
      return {
        ...state,
        join: { ...state.join, loading: true, error: null },
      }
    case actionTypes.JOIN.SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        join: { ...state.join, loading: false, error: null },
      }
    case actionTypes.JOIN.FAIL:
      return {
        ...state,
        user: action.payload.user,
        join: { ...state.join, loading: false, error: action.payload.error },
      }

    // 로그아웃
    case actionTypes.LOGOUT:
      return initialState

    // 유저정보
    case actionTypes.FETCH_USER.INDEX:
    case actionTypes.FETCH_USER.REQUEST:
      return { ...state, loading: true }
    case actionTypes.FETCH_USER.SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        loading: false,
      }
    case actionTypes.FETCH_USER.FAIL:
      return { ...state, loading: false, error: action.payload.error }

    // 클라이언트 로딩 시 유저세팅
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.payload.user,
      }
    default:
      return state
  }
}

export default reducer
