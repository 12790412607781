import React, { useState, useEffect } from 'react'
import { Form, Input, Select, Button, Space } from 'antd'
import getYmOptions from 'lib/getYmOptions'

const initialSearch = {
  name: '',
}

const StoreSearch = ({ meta, handleSearch, categories }) => {
  const [search, setSearch] = useState(initialSearch)

  useEffect(() => {
    setSearch({ ...initialSearch, ...meta })
  }, [meta])

  const handleSearchChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value })
  }

  const handleSubmit = () => {
    let _search = {
      name: search.name ? search.name : undefined,
    }
    handleSearch(_search)
  }

  const handleReset = () => {
    handleSearch({})
  }

  return (
    <Form
      layout="inline"
      style={{ marginBottom: 16, justifyContent: 'flex-end' }}
    >
      <Form.Item>
        <Input
          name="name"
          prefix={
            <span style={{ fontWeight: 'bold', color: 'gray' }}>
              가맹점명:{' '}
            </span>
          }
          value={search.name}
          onChange={handleSearchChange}
          style={{ width: 200 }}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button onClick={handleReset}>리셋</Button>
          <Button type="primary" onClick={handleSubmit}>
            검색
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default StoreSearch
