import React, { useState } from 'react';
import { Table, Tag, Space, Button, message, Typography } from 'antd';
import moment from 'moment';
import SmsSendModal from 'modules/sms/components/SmsSendModal';
import storeApi from 'modules/stores/api';

const StoreList = ({
  isAdmin,
  loading,
  downloading,
  stores,
  pagination,
  handleOpen,
  handlePageChange,
  handleGifticonModalOpen,
  handleSearch,
}) => {
  const handleAgencyLink = (agencyId) => handleSearch({ agency: agencyId });

  const [smsModalOpen, setSmsModalOpen] = useState(false);
  const [smsMobiles, setSmsMobiles] = useState([]);

  const handleStoreSmsModalOpen = (storeId) => {
    // 매장유저 불러오기
    storeApi.getStoreMobiles(storeId).then((res) => {
      setSmsMobiles(res.mobiles);
      setSmsModalOpen(true);
    });
  };

  const handleStoreSmsModalClose = () => {
    setSmsMobiles([]);
    setSmsModalOpen(false);
  };

  const columns = [
    {
      title: '대리점',
      dataIndex: 'agencyName',
      key: 'agencyName',
      // render: (agencyName, store) => displayAgencyName(store),
    },
    {
      title: '매장사장님',
      dataIndex: 'ceoName',
      key: 'ceoName',
      // render: (agencyName, store) => displayAgencyName(store),
    },
    {
      title: '매장사장님충전금',
      dataIndex: 'cash',
      key: 'cash',
      render: (cash, store) =>
        cash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
      title: '매장명',
      dataIndex: 'name',
      key: 'name',
      render: (name, store) => (
        <span style={{ fontWeight: 'bold' }}>{name}</span>
      ),
    },
    { title: '휴대폰', dataIndex: 'mobile', key: 'mobile' },
    {
      title: '기프티콘',
      dataIndex: 'gifticonCount',
      key: 'gifticonCount',
      align: 'center',
      render: (gifticonCount) => gifticonCount,
    },
    {
      title: '등록일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (createdAt) => (createdAt ? createdAt.substring(0, 10) : ''),
    },
    {
      title: '상태',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active) =>
        active === 'Y' ? (
          <span style={{ color: 'green' }}>발송</span>
        ) : (
          <span style={{ color: 'red' }}>중지</span>
        ),
    },
    {
      title: '관리',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      render: (text, store) => (
        <Space size="small">
          <Button onClick={() => handleStoreSmsModalOpen(store.id)}>
            문자발송
          </Button>
          <Button onClick={() => handleGifticonModalOpen(store)}>
            기프티콘
          </Button>
          {isAdmin && (
            <Button danger onClick={() => handleOpen(store)}>
              수정
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div></div>
        <div>
          <span style={{ fontWeight: 'bold', marginRight: 15 }}>
            <span style={{ color: 'gray' }}>Total: </span>
            {pagination.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </span>
        </div>
      </div>
      <Table
        bordered
        columns={columns}
        dataSource={stores}
        rowKey={(store) => store.id}
        loading={loading}
        pagination={{
          current: pagination.current_page,
          pageSize: pagination.per_page,
          total: pagination.total,
          onChange: handlePageChange,
          showSizeChanger: false,
        }}
      />
      <SmsSendModal
        open={smsModalOpen}
        onClose={handleStoreSmsModalClose}
        mobiles={smsMobiles}
      />
    </div>
  );
};

export default StoreList;
