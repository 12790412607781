import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AppLoader } from 'modules/core/app';
import { logout } from 'modules/core/account/store';
import history from 'lib/history';

// core modules routes
import { LoginPage } from 'modules/core/account';
import { AgenciesPage } from 'modules/core/agencies';
import { CeosPage } from 'modules/core/ceos';
import { DefaultLayout } from 'modules/core/layout';
import { StoresPage } from 'modules/stores';
import ReviewsPage from 'modules/reviews/containers/ReviewsPage';
import GifticonsPage from 'modules/gifticons/containers/GifticonsPage';
import SmsTemplatePage from 'modules/sms/containers/SmsTemplatePage';
// GUEST
import StoreServicePolicy from 'modules/pages/StoreServicePolicy';

const adminRoutes = [
  {
    name: '상품권 관리',
    sidebar: true,
    path: '/gifticons',
    exact: false,
    component: GifticonsPage,
    role: 'admin',
  },
  {
    name: '대리점 관리',
    sidebar: true,
    path: '/agencies',
    exact: true,
    component: AgenciesPage,
    role: 'admin',
  },
  {
    name: '매장사장님 관리',
    sidebar: true,
    path: '/ceos',
    exact: true,
    component: CeosPage,
  },
  {
    name: '매장 관리',
    sidebar: true,
    path: '/stores',
    exact: false,
    component: StoresPage,
  },
  {
    name: '리뷰 관리',
    sidebar: true,
    path: '/reviews',
    exact: false,
    component: ReviewsPage,
    // role: 'admin',
  },
  {
    name: '문자 템플릿 관리',
    sidebar: true,
    path: '/sms/templates',
    exact: false,
    component: SmsTemplatePage,
  },
  // {
  //   name: '등록현황',
  //   sidebar: true,
  //   path: '/stats',
  //   exact: true,
  //   component: StatsContainer,
  //   role: 'admin',
  // },
];

const sooRoutes = [];

const Routes = (props) => {
  const loaded = useSelector((store) => store.app.loaded);
  const dispatch = useDispatch();
  const user = useSelector((store) => store.account.user);
  const handleLogout = () => {
    dispatch(logout());
    history.push('/login');
  };

  function getRoutes(routes) {
    return routes.map((route) => {
      if (route.routes) {
        return route.routes.map((sub) => (
          <Route
            key={sub.path}
            path={sub.path}
            exact={sub.exact}
            component={sub.component}
          />
        ));
      }

      return (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      );
    });
  }

  if (!loaded) return <AppLoader />;

  const fixedAdminRoutes = (routes) =>
    user && user.loginId === 'soo' ? [...adminRoutes, ...routes] : adminRoutes;

  return (
    <>
      <Switch>
        <Route path="/" exact render={() => <Redirect to="/stores" />} />
        <Route path="/login" exact component={LoginPage} />
        <Route
          path="/policy/store/service"
          exact
          component={StoreServicePolicy}
        />
        <DefaultLayout
          user={user}
          handleLogout={handleLogout}
          routes={fixedAdminRoutes(sooRoutes)}
        >
          {getRoutes(fixedAdminRoutes(sooRoutes))}
        </DefaultLayout>
        <Route path="*" render={() => <Redirect to="/stores" />} />
      </Switch>
    </>
  );
};

export default Routes;
