import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import omit from 'lodash/omit'
import api from 'modules/core/account/api'
import { setToken } from 'lib/token'
import message from 'lib/message'
import {
  fetchAgencies,
  fetchOneAgencies,
  createAgencies,
  updateAgencies,
  deleteAgencies,
  openModal,
  closeModal,
  removeError,
} from '../../store'
import queryString from 'query-string'

import Page from 'components/Common/Page'
import AgencyForm from '../../components/AgencyForm'
import AgencyList from '../../components/AgencyList'
import AgencySearch from '../../components/AgencySearch'

import CeoForm from 'modules/core/ceos/components/CeoForm'
import {
  fetchCeos,
  createCeos,
  updateCeos,
  deleteCeos,
  openModal as openCeoModal,
  closeModal as closeCeoModal,
  removeError as removeCeoError,
} from 'modules/core/ceos/store'

const AgenciesPage = (props) => {
  const { history } = props

  const dispatch = useDispatch()
  const loading = useSelector((store) => !!store.agencies.loading)
  const agencies = useSelector((store) => store.agencies.agencies)
  const meta = useSelector((store) => store.agencies.meta)
  const modal = useSelector((store) => store.agencies.modal)
  const user = useSelector((store) => store.account.user)
  const ceoModal = useSelector((store) => store.ceos.modal)

  useEffect(() => {
    dispatch(fetchAgencies())
  }, [dispatch])

  const handleOpen = (agency) => {
    agency && dispatch(fetchOneAgencies(agency))
    dispatch(openModal())
  }

  const handlePageChange = (page) =>
    dispatch(fetchAgencies(omit({ ...meta, currentPage: page }, 'total')))
  const handleSearch = (search) =>
    dispatch(fetchAgencies({ ...meta, ...search }))

  const handleAgencyCeos = (meta) => {
    const text = queryString.stringify(meta)
    history.push(`/ceos?${text}`)
  }

  const forceLogin = (agencyId) => {
    api
      .forceLogin(agencyId)
      .then((response) => {
        try {
          if (response.token) {
            console.log(response.token)
            setToken(response.token)
            // setTimeout(() => window.location.reload(true), 300)
            setTimeout(() => (window.location.href = '/'), 300)
          }
        } catch (e) {}
      })
      .catch((error) => {
        message.error('로그인에 실패했습니다.')
      })
  }

  const handleOpenCeoModal = (agency) => {
    dispatch(openCeoModal(agency.id))
  }

  const handleCloseCeoModal = () => {
    dispatch(closeCeoModal())
    setTimeout(() => {
      dispatch(fetchAgencies())
    }, 100)
  }

  const handleCreateCeo = (ceo) => {
    dispatch(createCeos(ceo))
    setTimeout(() => {
      dispatch(fetchAgencies())
    }, 100)
  }

  return (
    <Page>
      <AgencySearch handleOpen={handleOpen} handleSearch={handleSearch} />
      <AgencyList
        user={user}
        forceLogin={forceLogin}
        loading={loading}
        meta={meta}
        data={agencies}
        handleOpen={handleOpen}
        handlePageChange={handlePageChange}
        handleAgencyCeos={handleAgencyCeos}
        handleOpenCeoModal={handleOpenCeoModal}
      />
      <AgencyForm
        open={modal.open}
        requesting={modal.requesting}
        errors={modal.error && modal.error.errors}
        agency={modal.agency}
        handleUpdate={(agency) => dispatch(updateAgencies(agency))}
        handleCreate={(agency) => dispatch(createAgencies(agency))}
        handleDelete={(agency) => dispatch(deleteAgencies(agency))}
        handleClose={() => dispatch(closeModal())}
        handleExit={() => dispatch(fetchAgencies(meta))}
        removeError={(payload) => dispatch(removeError(payload))}
      />
      <CeoForm
        open={ceoModal.open}
        requesting={ceoModal.requesting}
        errors={ceoModal.error && ceoModal.error.errors}
        ceo={ceoModal.ceo}
        agencyId={ceoModal.agencyId}
        handleUpdate={(ceo) => dispatch(updateCeos(ceo))}
        handleCreate={handleCreateCeo}
        handleDelete={(ceo) => dispatch(deleteCeos(ceo))}
        handleClose={handleCloseCeoModal}
        handleExit={() => dispatch(fetchCeos(meta))}
        removeError={(payload) => dispatch(removeCeoError(payload))}
      />
    </Page>
  )
}

export default AgenciesPage
