import request from 'lib/request'
import axios from 'lib/request/axios'

const api = {
  fetch: (meta) => request.get(`api/gifticons`, meta),
  update: (gifticonCode, active) =>
    request.put(`api/gifticons/${gifticonCode}`, { active: active }),
}

export default api
