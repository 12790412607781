import React, { useEffect, useState } from 'react';
import SmsTemplateList from '../components/SmsTemplateList';
import Page from 'components/Common/Page';

const SmsTemplatePage = () => {
  return (
    <Page>
      <SmsTemplateList />
    </Page>
  );
};

export default SmsTemplatePage;
