import React, { useState, useEffect } from 'react'
import { Table, Form, Input, Select, Button, Space } from 'antd'

const initialSearch = {
  name: '',
  active: '',
}

const GifticonList = (props) => {
  const { loading, gifticons, updateGifticon } = props

  const [search, setSearch] = useState(initialSearch)
  const [results, setResults] = useState([])

  useEffect(() => {
    handleSearch(gifticons)
  }, [gifticons])

  const handleInputChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value })
  }

  const handleActiveChange = (value) => setSearch({ ...search, active: value })

  const handleSearch = () => {
    setResults(
      gifticons
        .filter((gifticon) => {
          if (!search.active) return gifticon
          if (search.active && search.active === gifticon.active)
            return gifticon
        })
        .filter((gifticon) => {
          if (!search.name) return gifticon
          if (gifticon.name.includes(search.name)) return gifticon
        })
    )
  }

  const columns = [
    { title: '상품명', dataIndex: 'name' },
    { title: '브랜드', dataIndex: 'brand' },
    { title: '가격', dataIndex: 'price' },
    { title: '상태', dataIndex: 'state' },
    {
      title: '노출',
      dataIndex: 'active',
      render: (active, gifticon) =>
        active === 'Y' ? (
          <Button
            type="primary"
            onClick={() => updateGifticon(gifticon.code, 'N')}
          >
            노출중
          </Button>
        ) : (
          <Button
            type="primary"
            danger
            onClick={() => updateGifticon(gifticon.code, 'Y')}
          >
            미노출
          </Button>
        ),
    },
  ]

  return (
    <div>
      <div>
        <Form
          layout="inline"
          style={{ marginBottom: 16, justifyContent: 'flex-end' }}
        >
          <Form.Item>
            <Select
              name="active"
              defaultValue=""
              value={search.active}
              onChange={handleActiveChange}
              style={{ width: 150 }}
            >
              <Select.Option value="">= 노출 선택 =</Select.Option>
              <Select.Option value="Y">노출중</Select.Option>
              <Select.Option value="N">미노출</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Input
              name="name"
              prefix={
                <span style={{ fontWeight: 'bold', color: 'gray' }}>
                  이름:{' '}
                </span>
              }
              value={search.name}
              onChange={handleInputChange}
              style={{ width: 200 }}
            />
          </Form.Item>
          <Button onClick={handleSearch}>검색</Button>
        </Form>
      </div>
      <Table
        columns={columns}
        dataSource={results}
        rowKey={(gifticon) => gifticon.id}
        loading={loading}
      />
    </div>
  )
}

export default GifticonList
