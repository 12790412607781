import { takeLatest, takeEvery, all, call, put } from 'redux-saga/effects'
import { actionTypes } from './store'
import {
  fetchAgencies,
  createAgencies,
  fetchOneAgencies,
  updateAgencies,
  deleteAgencies,
  closeModal,
} from './store'
import api from './api'
import apiSaga from 'lib/saga/apiSaga'
import message from 'lib/message'

const {
  FETCH_AGENCIES,
  CREATE_AGENCIES,
  FETCH_ONE_AGENCIES,
  UPDATE_AGENCIES,
  DELETE_AGENCIES,
} = actionTypes

// 1. fetch
export function* fetchAgenciesSaga(action) {
  yield call(apiSaga, api.fetch, fetchAgencies, { apiPayload: action.payload })
}

// 2. create
export function* createAgenciesSaga(action) {
  const success = yield call(apiSaga, api.create, createAgencies, {
    apiPayload: action.payload,
  })
  if (success) {
    yield put(closeModal())
    message.success('대리점이 생성되었습니다.')
  } else {
    message.error('입력내용을 확인해주세요')
  }
}

// 3. fetchOne
export function* fetchOneAgenciesSaga(action) {
  yield call(apiSaga, api.fetchOne, fetchOneAgencies, {
    apiPayload: action.payload,
  })
}

// 4. update
export function* updateAgenciesSaga(action) {
  const success = yield call(apiSaga, api.update, updateAgencies, {
    apiPayload: action.payload,
  })
  if (success) {
    yield put(closeModal())
    message.success('대리점이 수정되었습니다.')
  } else {
    message.error('입력내용을 확인해주세요')
  }
}

// 5. delete
export function* deleteAgenciesSaga(action) {
  const success = yield call(apiSaga, api.delete, deleteAgencies, {
    apiPayload: action.payload,
  })
  if (success) {
    yield put(closeModal())
    message.success('대리점이 삭제되었습니다.')
  } else {
    message.error('삭제중 에러가 발생했습니다.')
  }
}

export default function* agenciesSagas() {
  yield all([
    takeEvery(FETCH_AGENCIES.INDEX, fetchAgenciesSaga),
    takeLatest(CREATE_AGENCIES.INDEX, createAgenciesSaga),
    takeEvery(FETCH_ONE_AGENCIES.INDEX, fetchOneAgenciesSaga),
    takeLatest(UPDATE_AGENCIES.INDEX, updateAgenciesSaga),
    takeLatest(DELETE_AGENCIES.INDEX, deleteAgenciesSaga),
  ])
}
