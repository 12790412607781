import request from 'lib/request'

const agenciesApi = {
  fetch: (meta) => request.get('/api/agencies', meta),
  fetchOne: (agency) => request.get(`/api/agencies/${agency.id}`),
  create: (agency) => request.post('/api/agencies', agency),
  update: (agency) => request.put(`/api/agencies/${agency.id}`, agency),
  delete: (agency) => request.del(`/api/agencies/${agency.id}`),
}

export default agenciesApi
