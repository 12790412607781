import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'antd'
import { Form, Input, Switch } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const initialCeo = {
  agencyId: null,
  loginId: '',
  password: '',
  username: '',
  mobile: '',
  active: 'Y',
}

const CeoForm = (props) => {
  const { requesting, errors, ceo, removeError, agencyId } = props
  const {
    open,
    handleClose,
    handleExit,
    handleCreate,
    handleUpdate,
    handleDelete,
  } = props
  const [inputs, setInputs] = useState(initialCeo)

  useEffect(() => {
    ceo && ceo.id && setInputs(ceo)
  }, [ceo])

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
    removeErrorWithName(errors, e.target.name)
  }
  const handleActiveChane = (value) => {
    setInputs({ ...inputs, active: value ? 'Y' : 'N' })
    removeErrorWithName(errors, 'active')
  }
  const removeErrorWithName = (errors, name) => {
    errors && errors[name] && removeError({ name })
  }

  const handleOnCreate = () => handleCreate({ ...inputs, agencyId: agencyId })
  const handleOnUpdate = () => handleUpdate(inputs)
  const handleDeleteConfirm = () => {
    Modal.confirm({
      title: '정말로 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      content: '삭제하면 데이터를 복구할수 없습니다.',
      okText: '삭제',
      cancelText: '취소',
      onOk() {
        handleDelete(inputs)
      },
    })
  }

  const handleModalExit = () => {
    setInputs(initialCeo)
    handleExit()
  }

  const createFooter = [
    <Button key="cancle" onClick={handleClose} disabled={requesting}>
      취소
    </Button>,
    <Button
      key="create"
      type="primary"
      onClick={handleOnCreate}
      disabled={requesting}
    >
      등록
    </Button>,
  ]

  const updateFooter = [
    <Button
      key="delete"
      type="primary"
      danger
      onClick={handleDeleteConfirm}
      disabled={requesting}
    >
      삭제
    </Button>,
    <Button key="cancle" onClick={handleClose} disabled={requesting}>
      취소
    </Button>,
    <Button
      key="update"
      type="primary"
      onClick={handleOnUpdate}
      disabled={requesting}
    >
      저장
    </Button>,
  ]

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  return (
    <Modal
      title={inputs.id ? '매장사장님 수정' : '매장사장님 등록'}
      visible={open}
      afterClose={handleModalExit}
      onCancel={handleClose}
      footer={inputs.id ? updateFooter : createFooter}
      maskClosable={false}
    >
      <Form {...layout} style={{ maxWidth: 360 }}>
        <Form.Item
          label="아이디"
          validateStatus={errors && errors.loginId && 'error'}
          help={errors && errors.loginId && errors.loginId[0]}
        >
          <Input
            name="loginId"
            value={inputs.loginId}
            onChange={handleInputChange}
            disabled={requesting}
            placeholder="숫자나 알파벳으로 4~20자"
          />
        </Form.Item>
        <Form.Item
          label="비밀번호"
          validateStatus={errors && errors.password && 'error'}
          help={errors && errors.password && errors.password[0]}
        >
          <Input
            type="password"
            name="password"
            value={inputs.password}
            onChange={handleInputChange}
            disabled={requesting}
            autoComplete="new-password"
            placeholder={inputs.id ? '변경시에만 입력' : '비밀번호는 4~16자'}
          />
        </Form.Item>
        <Form.Item
          label="사장님이름"
          validateStatus={errors && errors.username && 'error'}
          help={errors && errors.username && errors.username[0]}
        >
          <Input
            name="username"
            value={inputs.username}
            onChange={handleInputChange}
            disabled={requesting}
            placeholder="20자 이내"
          />
        </Form.Item>
        <Form.Item
          label="휴대폰"
          validateStatus={errors && errors.mobile && 'error'}
          help={errors && errors.mobile && errors.mobile[0]}
        >
          <Input
            name="mobile"
            value={inputs.mobile}
            onChange={handleInputChange}
            disabled={requesting}
            placeholder="숫자만 입력"
          />
        </Form.Item>
        <Form.Item
          label="활성"
          validateStatus={errors && errors.active && 'error'}
          help={errors && errors.active && errors.active[0]}
        >
          <Switch
            name="active"
            checked={inputs.active === 'Y'}
            onChange={handleActiveChane}
            disabled={requesting}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CeoForm
