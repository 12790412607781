import request from 'lib/request';

const api = {
  fetch: (meta) => request.get('/api/sms/templates', meta),
  fetchOne: (template) => request.get(`/api/sms/templates/${template.id}`),
  create: (template) => request.post('/api/sms/templates', template),
  update: (template) =>
    request.put(`/api/sms/templates/${template.id}`, template),
  delete: (template) => request.del(`/api/sms/templates/${template.id}`),

  sendSms: (payload) =>
    request.post(`/api/sms/templates/${payload.templateId}/send`, {
      mobiles: payload.mobiles,
    }),
  sendMobileMessage: (payload) =>
    request.post(`/api/sms/send`, {
      mobiles: payload.mobiles,
      content: payload.content,
    }),
};

export default api;
