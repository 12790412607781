// replace 1. Stores => Stores
// replace 2. STORES => STORES
// replace 3. stores => stores
import {
  makeAsyncActionsTypes,
  makeActionCreator,
  makeAsyncActionCreator,
} from 'lib/saga/reduxActionUtils'
import produce from 'immer'
/************************************************************************
 // 1. action types
 
 FETCH_STORES.INDEX
 FETCH_STORES.REQUEST
 FETCH_STORES.SUCCESS
 FETCH_STORES.FAIL
 NORMAL_ACTION
 ************************************************************************/
const prefix = '@APP/STORES'

export const actionTypes = {
  FETCH_STORES: makeAsyncActionsTypes(prefix, 'FETCH_STORES'),
  FETCH_ONE_STORES: makeAsyncActionsTypes(prefix, 'FETCH_ONE_STORES'),
  CREATE_STORES: makeAsyncActionsTypes(prefix, 'CREATE_STORES'),
  UPDATE_STORES: makeAsyncActionsTypes(prefix, 'UPDATE_STORES'),
  DELETE_STORES: makeAsyncActionsTypes(prefix, 'DELETE_STORES'),
  CLOSE_MODAL: `${prefix}/CLOSE_MODAL`,
  OPEN_MODAL: `${prefix}/OPEN_MODAL`,
  REMOVE_ERROR: `${prefix}/REMOVE_ERROR`,
  SET_STORES_META: `${prefix}/SET_STORES_META`,

  FETCH_PHOTOS: makeAsyncActionsTypes(prefix, 'FETCH_PHOTOS'),
  UPLOAD_PHOTOS: makeAsyncActionsTypes(prefix, 'UPLOAD_PHOTOS'),
  DELETE_PHOTOS: makeAsyncActionsTypes(prefix, 'DELETE_PHOTOS'),
  CLOSE_PHOTO_MODAL: `${prefix}/CLOSE_PHOTO_MODAL`,
  OPEN_PHOTO_MODAL: `${prefix}/OPEN_PHOTO_MODAL`,

  UPDATE_RETURN: makeAsyncActionsTypes(prefix, 'UPDATE_RETURN'),
  CLOSE_RETURN_MODAL: `${prefix}/CLOSE_RETURN_MODAL`,
  OPEN_RETURN_MODAL: `${prefix}/OPEN_RETURN_MODAL`,

  UPDATE_CASH: makeAsyncActionsTypes(prefix, 'UPDATE_CASH'),
  CLOSE_CASH_MODAL: `${prefix}/CLOSE_CASH_MODAL`,
  OPEN_CASH_MODAL: `${prefix}/OPEN_CASH_MODAL`,

  CLOSE_GIFTICON_MODAL: `${prefix}/CLOSE_GIFTICON_MODAL`,
  OPEN_GIFTICON_MODAL: `${prefix}/OPEN_GIFTICON_MODAL`,

  UPDATE_CONFIRM: makeAsyncActionsTypes(prefix, 'UPDATE_CONFIRM'),
  UPDATE_CONFIRM2: makeAsyncActionsTypes(prefix, 'UPDATE_CONFIRM2'),
  RESET_UNREAD_NOTICE: `${prefix}/RESET_UNREAD_NOTICE`,
}

/************************************************************************
// 2. action creator

fetchStores(payload)
fetchStores.request(payload)
fetchStores.success(payload)
fetchStores.fail(payload)
resetUser(payload)
************************************************************************/
export const fetchStores = makeAsyncActionCreator(actionTypes.FETCH_STORES)
export const fetchOneStores = makeAsyncActionCreator(
  actionTypes.FETCH_ONE_STORES
)
export const createStores = makeAsyncActionCreator(actionTypes.CREATE_STORES)
export const updateStores = makeAsyncActionCreator(actionTypes.UPDATE_STORES)
export const deleteStores = makeAsyncActionCreator(actionTypes.DELETE_STORES)
export const closeModal = makeActionCreator(actionTypes.CLOSE_MODAL)
export const openModal = makeActionCreator(actionTypes.OPEN_MODAL)
export const removeError = makeActionCreator(actionTypes.REMOVE_ERROR)
export const setStoresMeta = makeActionCreator(actionTypes.SET_STORES_META)

export const fetchPhotos = makeAsyncActionCreator(actionTypes.FETCH_PHOTOS)
export const uploadPhotos = makeAsyncActionCreator(actionTypes.UPLOAD_PHOTOS)
export const deletePhotos = makeAsyncActionCreator(actionTypes.DELETE_PHOTOS)
export const closePhotoModal = makeActionCreator(actionTypes.CLOSE_PHOTO_MODAL)
export const openPhotoModal = makeActionCreator(actionTypes.OPEN_PHOTO_MODAL)
export const removePhotoError = makeActionCreator(
  actionTypes.REMOVE_PHOTO_ERROR
)

export const updateReturn = makeAsyncActionCreator(actionTypes.UPDATE_RETURN)
export const closeReturnModal = makeActionCreator(
  actionTypes.CLOSE_RETURN_MODAL
)
export const openReturnModal = makeActionCreator(actionTypes.OPEN_RETURN_MODAL)

export const updateCash = makeAsyncActionCreator(actionTypes.UPDATE_CASH)
export const closeCashModal = makeActionCreator(actionTypes.CLOSE_CASH_MODAL)
export const openCashModal = makeActionCreator(actionTypes.OPEN_CASH_MODAL)

export const closeGifticonModal = makeActionCreator(
  actionTypes.CLOSE_GIFTICON_MODAL
)
export const openGifticonModal = makeActionCreator(
  actionTypes.OPEN_GIFTICON_MODAL
)

export const updateConfirm = makeAsyncActionCreator(actionTypes.UPDATE_CONFIRM)
export const updateConfirm2 = makeAsyncActionCreator(
  actionTypes.UPDATE_CONFIRM2
)
export const resetUnreadNotice = makeActionCreator(
  actionTypes.RESET_UNREAD_NOTICE
)
/***********************************************************************
// 3. reducer
error format
{
  status: 422,
  message: "입력값을 확인해주세요.",
  errors: [
    email: [{...}]
  ]
}

************************************************************************/
const initialModalState = {
  open: false,
  requesting: false,
  error: null,
  store: null,
}

const initialPhotoModalState = {
  open: false,
  requesting: false,
  error: null,
  photos: [],
  store: null,
  type: '',
  file: null,
}

const initialReturnModalState = {
  open: false,
  requesting: false,
  error: null,
  store: null,
  file: null,
}

const initiaConfirmModalState = {
  open: false,
  requesting: false,
  error: null,
  store: null,
  file: null,
}

const initiaConfirm2ModalState = {
  open: false,
  requesting: false,
  error: null,
  store: null,
  file: null,
}
const initialCashModalState = {
  open: false,
  requesting: false,
  error: null,
  store: null,
  file: null,
}

const initialGifticonModalState = {
  open: false,
  store: null,
}

const initialState = {
  loading: false,
  error: null,
  stores: [],
  regions: [],
  categories: [],
  modal: initialModalState,
  meta: {
    total: 0,
    per_page: 10,
    current_page: 1,

    page: 1,
    payment: null,
    return: null,
    confirm: null,
    confirm2: null,
    region: null,
    coopangId: null,
    name: null,
    agency: null,
    originalAgency: null,
  },
  search: {},
  pagination: { total: 0, per_page: 10, current_page: 1 },
  unreadNotice: 0,
  photoModal: initialPhotoModalState,
  returnModal: initialReturnModalState,
  cashModal: initialCashModalState,
  confirmModal: initiaConfirmModalState,
  confirm2Modal: initiaConfirm2ModalState,
  gifticonModal: initialGifticonModalState,
}
// fds

const storesReducer = function (state = initialState, action) {
  switch (action.type) {
    // FETCH_STORES
    case actionTypes.FETCH_STORES.INDEX:
    case actionTypes.FETCH_STORES.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true
      })
    case actionTypes.FETCH_STORES.SUCCESS:
      return {
        ...state,
        stores: action.payload.stores.data,
        meta: {
          total: action.payload.stores.total,
          per_page: action.payload.stores.per_page,
          current_page: action.payload.stores.current_page,
          page: action.payload.stores.current_page,
          ...action.payload.search,
        },
        pagination: {
          total: action.payload.stores.total,
          per_page: action.payload.stores.per_page,
          current_page: action.payload.stores.current_page,
        },
        search: {
          ...action.payload.search,
        },
        loading: false,
      }
    case actionTypes.FETCH_STORES.FAIL:
      return { ...state, loading: false, error: action.payload.error }

    /**************************************************************************************************
      가맹점 모달
    **************************************************************************************************/
    // FETCH_ONE_STORES
    case actionTypes.FETCH_ONE_STORES.INDEX:
    case actionTypes.FETCH_ONE_STORES.REQUEST:
      return { ...state, modal: { ...state.modal, requesting: true } }
    case actionTypes.FETCH_ONE_STORES.SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          requesting: false,
          store: action.payload.store,
        },
      }
    case actionTypes.FETCH_ONE_STORES.FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          requesting: false,
          error: action.payload.error,
        },
      }

    // CREATE_STORES
    case actionTypes.CREATE_STORES.INDEX:
    case actionTypes.CREATE_STORES.REQUEST:
      return {
        ...state,
        modal: { ...state.modal, open: true, requesting: true },
      }
    case actionTypes.CREATE_STORES.SUCCESS:
      return { ...state, modal: { ...state.modal, requesting: false } }
    case actionTypes.CREATE_STORES.FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          requesting: false,
          error: action.payload.error,
        },
      }

    // UPDATE_STORES
    case actionTypes.UPDATE_STORES.INDEX:
    case actionTypes.UPDATE_STORES.REQUEST:
      return { ...state, modal: { ...state.modal, loading: true } }
    case actionTypes.UPDATE_STORES.SUCCESS:
      return { ...state, modal: { ...state.modal, loading: false }, store: {} }
    case actionTypes.UPDATE_STORES.FAIL:
      return {
        ...state,
        modal: { ...state.modal, loading: false, error: action.payload.error },
      }

    // DELETE_STORES
    case actionTypes.DELETE_STORES.INDEX:
    case actionTypes.DELETE_STORES.REQUEST:
      return { ...state, modal: { ...state.modal, loading: true } }
    case actionTypes.DELETE_STORES.SUCCESS:
      return { ...state, modal: { ...state.modal, loading: false } }
    case actionTypes.DELETE_STORES.FAIL:
      return {
        ...state,
        modal: { ...state.modal, loading: false, error: action.payload.error },
      }

    // OPEN_MODAL
    case actionTypes.OPEN_MODAL:
      return {
        ...state,
        modal: { ...state.modal, open: true, ceoId: action.payload },
      }
    // CLOSE_MODAL
    case actionTypes.CLOSE_MODAL:
      return { ...state, modal: initialModalState }
    // REMOVE_ERROR
    case actionTypes.REMOVE_ERROR:
      return produce(state, (draft) => {
        draft.modal.error.errors[action.payload.name] = undefined
      })
    // SET_STORES_META
    case actionTypes.SET_STORES_META:
      return { ...state, meta: { ...state.meta, ...action.payload } }

    /**************************************************************************************************
      사진 모달
    **************************************************************************************************/
    // FETCH_PHOTOS
    case actionTypes.FETCH_PHOTOS.INDEX:
    case actionTypes.FETCH_PHOTOS.REQUEST:
      return { ...state, photoModal: { ...state.photoModal, requesting: true } }
    case actionTypes.FETCH_PHOTOS.SUCCESS:
      return {
        ...state,
        photoModal: {
          ...state.photoModal,
          requesting: false,
          photos: action.payload.photos,
        },
      }
    case actionTypes.FETCH_PHOTOS.FAIL:
      return {
        ...state,
        photoModal: {
          ...state.photoModal,
          requesting: false,
          error: action.payload.error,
        },
      }

    // UPLOAD_PHOTOS
    case actionTypes.UPLOAD_PHOTOS.INDEX:
    case actionTypes.UPLOAD_PHOTOS.REQUEST:
      return {
        ...state,
        photoModal: { ...state.photoModal, open: true, requesting: true },
      }
    case actionTypes.UPLOAD_PHOTOS.SUCCESS:
      return {
        ...state,
        photoModal: {
          ...state.photoModal,
          requesting: false,
          photos: action.payload.photos,
        },
      }
    case actionTypes.UPLOAD_PHOTOS.FAIL:
      return {
        ...state,
        photoModal: {
          ...state.photoModal,
          requesting: false,
          error: action.payload.error,
        },
      }

    // DELETE_PHOTOS
    case actionTypes.DELETE_PHOTOS.INDEX:
    case actionTypes.DELETE_PHOTOS.REQUEST:
      return { ...state, photoModal: { ...state.photoModal, loading: true } }
    case actionTypes.DELETE_PHOTOS.SUCCESS:
      return {
        ...state,
        photoModal: {
          ...state.photoModal,
          loading: false,
          photos: action.payload.photos,
        },
      }
    case actionTypes.DELETE_PHOTOS.FAIL:
      return {
        ...state,
        photoModal: {
          ...state.photoModal,
          loading: false,
          error: action.payload.error,
        },
      }

    // OPEN_PHOTO_MODAL
    case actionTypes.OPEN_PHOTO_MODAL:
      return {
        ...state,
        photoModal: {
          ...state.photoModal,
          open: true,
          store: action.payload.store,
          type: action.payload.type,
        },
      }
    // CLOSE_PHOTO_MODAL
    case actionTypes.CLOSE_PHOTO_MODAL:
      return { ...state, photoModal: initialReturnModalState }

    /**************************************************************************************************
      반려 모달
    **************************************************************************************************/
    // UPDATE_RETURN
    case actionTypes.UPDATE_RETURN.INDEX:
    case actionTypes.UPDATE_RETURN.REQUEST:
      return { ...state, returnModal: { ...state.returnModal, loading: true } }
    case actionTypes.UPDATE_RETURN.SUCCESS:
      return {
        ...state,
        returnModal: { ...state.returnModal, loading: false },
        store: {},
      }
    case actionTypes.UPDATE_RETURN.FAIL:
      return {
        ...state,
        returnModal: {
          ...state.returnModal,
          loading: false,
          error: action.payload.error,
        },
      }
    // OPEN_RETURN_MODAL
    case actionTypes.OPEN_RETURN_MODAL:
      return {
        ...state,
        returnModal: {
          ...state.returnModal,
          open: true,
          store: action.payload.store,
        },
      }
    // CLOSE_RETURN_MODAL
    case actionTypes.CLOSE_RETURN_MODAL:
      return { ...state, returnModal: initialPhotoModalState }

    /**************************************************************************************************
      충전금 모달
    **************************************************************************************************/
    // UPDATE_CASH
    case actionTypes.UPDATE_CASH.INDEX:
    case actionTypes.UPDATE_CASH.REQUEST:
      return { ...state, cashModal: { ...state.cashModal, loading: true } }
    case actionTypes.UPDATE_CASH.SUCCESS:
      return {
        ...state,
        cashModal: { ...state.cashModal, loading: false },
        store: {},
      }
    case actionTypes.UPDATE_CASH.FAIL:
      return {
        ...state,
        cashModal: {
          ...state.cashModal,
          loading: false,
          error: action.payload.error,
        },
      }
    // OPEN_CASH_MODAL
    case actionTypes.OPEN_CASH_MODAL:
      return {
        ...state,
        cashModal: {
          ...state.cashModal,
          open: true,
          store: action.payload.store,
        },
      }
    // CLOSE_CASH_MODAL
    case actionTypes.CLOSE_CASH_MODAL:
      return { ...state, cashModal: initialCashModalState }

    /**************************************************************************************************
      gifticon 모달
    **************************************************************************************************/
    // OPEN_GIFTICON_MODAL
    case actionTypes.OPEN_GIFTICON_MODAL:
      return {
        ...state,
        gifticonModal: {
          ...state.gifticonModal,
          open: true,
          store: action.payload.store,
        },
      }
    // CLOSE_CASH_MODAL
    case actionTypes.CLOSE_GIFTICON_MODAL:
      return { ...state, gifticonModal: initialGifticonModalState }

    /**************************************************************************************************
      검수 모달
    **************************************************************************************************/
    // UPDATE_CONFIRM
    case actionTypes.UPDATE_CONFIRM.INDEX:
    case actionTypes.UPDATE_CONFIRM.REQUEST:
      return {
        ...state,
        confirmModal: { ...state.confirmModal, loading: true },
      }
    case actionTypes.UPDATE_CONFIRM.SUCCESS:
      return {
        ...state,
        confirmModal: { ...state.confirmModal, loading: false },
        store: {},
      }
    case actionTypes.UPDATE_CONFIRM.FAIL:
      return {
        ...state,
        confirmModal: {
          ...state.confirmModal,
          loading: false,
          error: action.payload.error,
        },
      }
    /**************************************************************************************************
      승인 모달
    **************************************************************************************************/
    // UPDATE_CONFIRM
    case actionTypes.UPDATE_CONFIRM2.INDEX:
    case actionTypes.UPDATE_CONFIRM2.REQUEST:
      return {
        ...state,
        confirm2Modal: { ...state.confirm2Modal, loading: true },
      }
    case actionTypes.UPDATE_CONFIRM2.SUCCESS:
      return {
        ...state,
        confirm2Modal: { ...state.confirm2Modal, loading: false },
        store: {},
      }
    case actionTypes.UPDATE_CONFIRM2.FAIL:
      return {
        ...state,
        confirm2Modal: {
          ...state.confirm2Modal,
          loading: false,
          error: action.payload.error,
        },
      }
    /**************************************************************************************************
      안읽은 공지사항 reset
    **************************************************************************************************/
    case actionTypes.RESET_UNREAD_NOTICE:
      return { ...state, unreadNotice: 0 }
    /**************************************************************************************************
      
    **************************************************************************************************/
    default:
      return state
  }
}

export default storesReducer
