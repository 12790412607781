import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'antd'
import {
  Form,
  Input,
  Radio,
  Divider,
  Upload,
  Row,
  Col,
  Space,
  Table,
  Descriptions,
  Typography,
  InputNumber,
  Select,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import getCheckedToken from 'lib/request/getCheckedToken'
import PhotoViewer from '../StorePhoto/PhotoViewer'
import storesApi from 'modules/stores/api'
import nl2br from 'react-nl2br'
import message from 'lib/message'
import format from 'lib/format'

const { Text, Link } = Typography
const { Option } = Select
// const apiUrl = process.env.REACT_APP_API_URL

const initialCash = {
  type: 'add',
  cash: '',
  title: '',
}

const CashModal = (props) => {
  const { isAdmin, modal, removeError, search } = props
  const { handleClose, handleExit, handleUpdate } = props
  const { open, requesting, errors, store } = modal

  const [inputs, setInputs] = useState(initialCash)
  const [loading, setLoading] = useState(false)
  const [storeCashes, setStoreCashes] = useState([])
  const [currentStore, setCurrentStore] = useState(store)

  useEffect(() => {
    if (store) fetchStoreCashes()
    if (store) fetchStore()
    return () => {
      setInputs(initialCash)
    }
  }, [store])

  const fetchStore = () => {
    storesApi.fetchOne(store).then((response) => {
      setCurrentStore(response.store)
    })
  }

  const fetchStoreCashes = () => {
    setLoading(true)
    storesApi
      .storeCashes(store)
      .then((response) => {
        setStoreCashes(response.store_cashes)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const createStoreCash = () => {
    if (!inputs.cash) return false
    setLoading(true)
    storesApi
      .cashChange(
        store,
        inputs.type === 'minus' ? inputs.cash * -1 : inputs.cash
      )
      .then((response) => {
        message.success(
          inputs.type === 'add' ? '충전되었습니다.' : '차감되었습니다.'
        )
        setInputs(initialCash)
      })
      .catch((error) => {
        message.error(
          inputs.type === 'add' ? '충전에러입니다.' : '차감에러입니다.'
        )
      })
      .finally(() => {
        fetchStoreCashes()
        fetchStore()
        handleExit()
        setLoading(false)
      })
  }

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
    removeErrorWithName(errors, e.target.name)
  }

  const handleCashChange = (e) => {
    const { value: inputValue } = e.target
    const reg = /^-?\d*(\.\d*)?$/

    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      setInputs({ ...inputs, cash: inputValue })
    }
  }

  const removeErrorWithName = (errors, name) => {
    errors && errors[name] && removeError({ name })
  }

  const footer = [
    <Button
      type="primary"
      key="cancle"
      onClick={() => {
        handleClose()
        handleExit()
      }}
      disabled={loading}
    >
      닫기
    </Button>,
  ]

  const handleChangeCashType = (value) => setInputs({ ...inputs, type: value })

  const cashInputBefore = (
    <Select
      value={inputs.type}
      style={{ width: 60 }}
      onChange={handleChangeCashType}
    >
      <Option value="add">+</Option>
      <Option value="minus">-</Option>
    </Select>
  )

  return (
    currentStore && (
      <Modal
        title={'충전금 관리'}
        visible={open}
        afterClose={handleExit}
        onCancel={handleClose}
        footer={footer}
        maskClosable={false}
      >
        <div>
          <Descriptions
            bordered
            layout="vertical"
            size="small"
            style={{ marginBottom: '20px' }}
          >
            <Descriptions.Item label="매장명">
              <Text strong>{currentStore.name}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="휴대폰">
              <Text strong>{currentStore.mobile}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="충전금">
              <Text strong mark>
                {currentStore.cash
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Text>
            </Descriptions.Item>
          </Descriptions>
          {isAdmin && (
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    validateStatus={errors && errors.storeId && 'error'}
                    help={errors && errors.storeId && errors.storeId[0]}
                  >
                    <Input
                      name="cash"
                      addonBefore={cashInputBefore}
                      addonAfter="원"
                      value={inputs.cash}
                      onChange={handleCashChange}
                      disabled={loading}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    danger={inputs.type === 'minus'}
                    disabled={loading}
                    onClick={createStoreCash}
                  >
                    {inputs.cash
                      ? inputs.cash
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      : 0}
                    원 {inputs.type === 'add' ? '충전' : '차감'}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
          <Table
            size="small"
            scroll={{
              y: 240,
            }}
            columns={[
              {
                title: '날자',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (createdAt) => (
                  <Text style={{ fontSize: 12 }}>
                    {format.datetime(createdAt)}
                  </Text>
                ),
              },
              {
                title: '내용',
                dataIndex: 'title',
                key: 'title',
                render: (title) => (
                  <Text style={{ fontSize: 12 }}>{title}</Text>
                ),
              },
              {
                title: '금액',
                dataIndex: 'cash',
                key: 'cash',
                render: (cash) => (
                  <Text style={{ fontSize: 12 }}>
                    {cash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </Text>
                ),
              },
              {
                title: '잔액',
                dataIndex: 'storeCash',
                key: 'storeCash',
                render: (storeCash) => (
                  <Text style={{ fontSize: 12 }}>
                    {storeCash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </Text>
                ),
              },
            ]}
            dataSource={storeCashes}
            rowKey={(store) => store.id}
            loading={loading}
            pagination={false}
          />
        </div>
      </Modal>
    )
  )
}

export default CashModal
