import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Button, message } from 'antd'
import accountApi from 'modules/core/account/api'

const initialInputs = {
  old_password: '',
  new_password: '',
  new_password_confirm: '',
}

const PasswordModal = ({ open, handleClose }) => {
  const [inputs, setInputs] = useState(initialInputs)
  const [requesting, setRequesting] = useState(false)

  useEffect(() => {
    return () => {
      setInputs(initialInputs)
    }
  }, [])

  const handleExit = () => setInputs(initialInputs)

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }

  const handleSubmit = () => {
    if (
      !inputs.old_password ||
      !inputs.new_password ||
      !inputs.new_password_confirm
    ) {
      message.error('입력사항을 모두 입력해주세요.')
      return
    }
    if (inputs.new_password !== inputs.new_password_confirm) {
      message.error('신규 비밀번호와 비밀번호 확인이 일치하지 않습니다.')
      return
    }

    setRequesting(true)
    accountApi
      .password(inputs)
      .then((response) => {
        message.success('비밀번호가 변경되었습니다.')
        setRequesting(false)
        handleClose()
      })
      .catch((error) => {
        message.error(error.message)
        setRequesting(false)
      })
  }

  const footer = [
    <Button key="cancle" onClick={handleClose} disabled={requesting}>
      취소
    </Button>,
    <Button
      key="create"
      type="primary"
      onClick={handleSubmit}
      disabled={requesting}
    >
      변경
    </Button>,
  ]

  return (
    <Modal
      width={420}
      title="비밀번호 변경"
      visible={open}
      afterClose={handleExit}
      onCancel={handleClose}
      footer={footer}
      maskClosable={false}
    >
      <Form>
        <Form.Item label="현재 비밀번호">
          <Input.Password
            name="old_password"
            value={inputs.old_password}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label="신규 비밀번호">
          <Input.Password
            name="new_password"
            value={inputs.new_password}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label="비밀번호 확인">
          <Input.Password
            name="new_password_confirm"
            value={inputs.new_password_confirm}
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PasswordModal
