// replace 1. Agencies => Stores
// replace 2. AGENCIES => STORES
// replace 3. agencies => stores
import {
  makeAsyncActionsTypes,
  makeActionCreator,
  makeAsyncActionCreator,
} from 'lib/saga/reduxActionUtils'
import produce from 'immer'
/************************************************************************
 // 1. action types
 
 FETCH_AGENCIES.INDEX
 FETCH_AGENCIES.REQUEST
 FETCH_AGENCIES.SUCCESS
 FETCH_AGENCIES.FAIL
 NORMAL_ACTION
 ************************************************************************/
const prefix = '@APP/AGENCIES'

export const actionTypes = {
  FETCH_AGENCIES: makeAsyncActionsTypes(prefix, 'FETCH_AGENCIES'),
  FETCH_ONE_AGENCIES: makeAsyncActionsTypes(prefix, 'FETCH_ONE_AGENCIES'),
  CREATE_AGENCIES: makeAsyncActionsTypes(prefix, 'CREATE_AGENCIES'),
  UPDATE_AGENCIES: makeAsyncActionsTypes(prefix, 'UPDATE_AGENCIES'),
  DELETE_AGENCIES: makeAsyncActionsTypes(prefix, 'DELETE_AGENCIES'),
  CLOSE_MODAL: `${prefix}/CLOSE_MODAL`,
  OPEN_MODAL: `${prefix}/OPEN_MODAL`,
  REMOVE_ERROR: `${prefix}/REMOVE_ERROR`,
}

/************************************************************************
// 2. action creator

fetchAgencies(payload)
fetchAgencies.request(payload)
fetchAgencies.success(payload)
fetchAgencies.fail(payload)
resetUser(payload)
************************************************************************/
export const fetchAgencies = makeAsyncActionCreator(actionTypes.FETCH_AGENCIES)
export const fetchOneAgencies = makeAsyncActionCreator(
  actionTypes.FETCH_ONE_AGENCIES
)
export const createAgencies = makeAsyncActionCreator(
  actionTypes.CREATE_AGENCIES
)
export const updateAgencies = makeAsyncActionCreator(
  actionTypes.UPDATE_AGENCIES
)
export const deleteAgencies = makeAsyncActionCreator(
  actionTypes.DELETE_AGENCIES
)
export const closeModal = makeActionCreator(actionTypes.CLOSE_MODAL)
export const openModal = makeActionCreator(actionTypes.OPEN_MODAL)
export const removeError = makeActionCreator(actionTypes.REMOVE_ERROR)
/***********************************************************************
// 3. reducer
error format
{
  status: 422,
  message: "입력값을 확인해주세요.",
  errors: [
    email: [{...}]
  ]
}

************************************************************************/
const initialModalState = {
  open: false,
  requesting: false,
  error: null,
  agency: null,
}

const initialState = {
  loading: false,
  error: null,
  agencies: [],
  modal: initialModalState,
}

const agenciesReducer = function (state = initialState, action) {
  switch (action.type) {
    // FETCH_AGENCIES
    case actionTypes.FETCH_AGENCIES.INDEX:
    case actionTypes.FETCH_AGENCIES.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true
      })
    case actionTypes.FETCH_AGENCIES.SUCCESS:
      return {
        ...state,
        agencies: [...action.payload.agencies],
        // agencies: action.payload.agencies,
        loading: false,
      }
    case actionTypes.FETCH_AGENCIES.FAIL:
      return { ...state, loading: false, error: action.payload.error }

    // FETCH_ONE_AGENCIES
    case actionTypes.FETCH_ONE_AGENCIES.INDEX:
    case actionTypes.FETCH_ONE_AGENCIES.REQUEST:
      return { ...state, modal: { ...state.modal, requesting: true } }
    case actionTypes.FETCH_ONE_AGENCIES.SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          requesting: false,
          agency: action.payload.agency,
        },
      }
    case actionTypes.FETCH_ONE_AGENCIES.FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          requesting: false,
          error: action.payload.error,
        },
      }

    // CREATE_AGENCIES
    case actionTypes.CREATE_AGENCIES.INDEX:
    case actionTypes.CREATE_AGENCIES.REQUEST:
      return {
        ...state,
        modal: { ...state.modal, open: true, requesting: true },
      }
    case actionTypes.CREATE_AGENCIES.SUCCESS:
      return { ...state, modal: { ...state.modal, requesting: false } }
    case actionTypes.CREATE_AGENCIES.FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          requesting: false,
          error: action.payload.error,
        },
      }

    // UPDATE_AGENCIES
    case actionTypes.UPDATE_AGENCIES.INDEX:
    case actionTypes.UPDATE_AGENCIES.REQUEST:
      return { ...state, modal: { ...state.modal, loading: true } }
    case actionTypes.UPDATE_AGENCIES.SUCCESS:
      return { ...state, modal: { ...state.modal, loading: false }, agency: {} }
    case actionTypes.UPDATE_AGENCIES.FAIL:
      return {
        ...state,
        modal: { ...state.modal, loading: false, error: action.payload.error },
      }

    // DELETE_AGENCIES
    case actionTypes.DELETE_AGENCIES.INDEX:
    case actionTypes.DELETE_AGENCIES.REQUEST:
      return { ...state, modal: { ...state.modal, loading: true } }
    case actionTypes.DELETE_AGENCIES.SUCCESS:
      return { ...state, modal: { ...state.modal, loading: false } }
    case actionTypes.DELETE_AGENCIES.FAIL:
      return {
        ...state,
        modal: { ...state.modal, loading: false, error: action.payload.error },
      }

    // OPEN_MODAL
    case actionTypes.OPEN_MODAL:
      return { ...state, modal: { ...state.modal, open: true } }

    // CLOSE_MODAL
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        modal: initialModalState,
      }

    case actionTypes.REMOVE_ERROR:
      return produce(state, (draft) => {
        draft.modal.error.errors[action.payload.name] = undefined
      })
    default:
      return state
  }
}

export default agenciesReducer
