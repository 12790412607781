import { format } from 'date-fns';

const roles = {
  admin: '어드민',
  branche: '대행사',
  agency: '대리점',
  customer: '승인대기자',
};

export const displayDate = (date) =>
  date ? format(new Date(date), 'yyyy.MM.dd') : '';
export const displayDateTime = (date) =>
  date ? format(new Date(date), 'yyyy.MM.dd HH:mm:ss') : '';
export const displayPhone = (phone) =>
  phone
    ? phone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')
    : '';
export const displayRole = (role) => (role ? roles[role] : '');
export const shorten = (str, max = null) => {
  if (!max || !str) return str;
  return str.length > max + 3 ? str.substring(0, max) + '...' : str;
};

const formatObj = {
  date: displayDate,
  datetime: displayDateTime,
  phone: displayPhone,
  role: displayRole,
  shorten,
};
export default formatObj;
