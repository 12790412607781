import React from 'react'
import { Layout } from 'antd'
const { Content, Footer, Header } = Layout

const Page = ({ children, header, footer, contentStyle }) => {
  return (
    <>
      {header && (
        <Header className="site-layout-background" style={{ padding: 0 }} />
      )}
      <Content>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 300, ...contentStyle }}
        >
          {children}
        </div>
      </Content>
      {footer && (
        <Footer style={{ textAlign: 'center' }}>
          Oin Design ©2020 Created by Oin
        </Footer>
      )}
    </>
  )
}

export default Page
