import { takeLatest, takeEvery, all, call, put } from 'redux-saga/effects'
import { actionTypes } from './store'
import {
  fetchCeos,
  createCeos,
  fetchOneCeos,
  updateCeos,
  deleteCeos,
  closeModal,
} from './store'
import api from './api'
import apiSaga from 'lib/saga/apiSaga'
import message from 'lib/message'

const { FETCH_CEOS, CREATE_CEOS, FETCH_ONE_CEOS, UPDATE_CEOS, DELETE_CEOS } =
  actionTypes

// 1. fetch
export function* fetchCeosSaga(action) {
  yield call(apiSaga, api.fetch, fetchCeos, { apiPayload: action.payload })
}

// 2. create
export function* createCeosSaga(action) {
  const success = yield call(apiSaga, api.create, createCeos, {
    apiPayload: action.payload,
  })
  if (success) {
    yield put(closeModal())
    message.success('사장님계정이 생성되었습니다.')
  } else {
    message.error('입력내용을 확인해주세요')
  }
}

// 3. fetchOne
export function* fetchOneCeosSaga(action) {
  yield call(apiSaga, api.fetchOne, fetchOneCeos, {
    apiPayload: action.payload,
  })
}

// 4. update
export function* updateCeosSaga(action) {
  const success = yield call(apiSaga, api.update, updateCeos, {
    apiPayload: action.payload,
  })
  if (success) {
    yield put(closeModal())
    message.success('사장님계정이 수정되었습니다.')
  } else {
    message.error('입력내용을 확인해주세요')
  }
}

// 5. delete
export function* deleteCeosSaga(action) {
  const success = yield call(apiSaga, api.delete, deleteCeos, {
    apiPayload: action.payload,
  })
  if (success) {
    yield put(closeModal())
    message.success('사장님계정이 삭제되었습니다.')
  } else {
    message.error('삭제중 에러가 발생했습니다.')
  }
}

export default function* ceosSagas() {
  yield all([
    takeEvery(FETCH_CEOS.INDEX, fetchCeosSaga),
    takeLatest(CREATE_CEOS.INDEX, createCeosSaga),
    takeEvery(FETCH_ONE_CEOS.INDEX, fetchOneCeosSaga),
    takeLatest(UPDATE_CEOS.INDEX, updateCeosSaga),
    takeLatest(DELETE_CEOS.INDEX, deleteCeosSaga),
  ])
}
