import React, { useEffect, useState } from 'react'
import Page from 'components/Common/Page'
import api from '../../api'
import message from 'lib/message.js'

import GifticonList from '../../components/GifticonList'

const initialGifticons = []
const ReviewsPage = (props) => {
  const [loading, setLoading] = useState(false)
  const [gifticons, setGifticons] = useState(initialGifticons)

  useEffect(() => {
    fetchGifticons()
  }, [])

  const fetchGifticons = () => {
    setLoading(true)
    api
      .fetch({})
      .then((response) => {
        console.log(response)
        setGifticons(response.gifticons)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateGifticon = (gifticonCode, active) => {
    setLoading(true)
    api
      .update(gifticonCode, active)
      .then((response) => {
        message.success('변경되었습니다')
        console.log(response)
      })
      .catch((error) => {
        message.error('변경중 에러가 발생했습니다')
        console.log(error)
      })
      .finally(() => {
        fetchGifticons()
        setLoading(false)
      })
  }

  return (
    <Page>
      <GifticonList
        gifticons={gifticons}
        loading={loading}
        updateGifticon={updateGifticon}
      />
      {/* <GiftishowSample /> */}
    </Page>
  )
}

export default ReviewsPage
