import fetch from 'axios'
import getCheckedToken from './getCheckedToken'

const baseURL = process.env.REACT_APP_API_URL
// axios 인스텐스 생성
const axios = fetch.create({ baseURL: baseURL })

axios.interceptors.request.use(
  (config) => {
    if (config.baseURL === baseURL && !config.headers.Authorization) {
      const token = getCheckedToken()
      if (token) config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

// 로그인/로그아웃 시에 헤더를 글로벌하게 세팅
export const setAuthHeader = (token) => {
  axios.defaults.headers['Authorization'] = `Bearer ${token}`
  return axios.defaults.headers
}

export default axios
