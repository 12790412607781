import React, { useEffect, useState } from 'react';
import {
  Modal,
  Form,
  Input,
  Switch,
  Select,
  Button,
  Row,
  Col,
  Space,
  message,
} from 'antd';
import api from '../api';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

const initInputs = {
  content: '',
  mobiles: [],
};

const SmsSendModal = (props) => {
  const { open, onClose, mobiles } = props;
  const [inputs, setInputs] = useState(initInputs);
  const [templates, setTemplates] = useState([]);
  const [requesting, setRequesting] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [mobileText, setMobileText] = useState('');
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    open && fetch();
    setInputs({ ...inputs, mobiles: mobiles });
  }, [open, mobiles]);

  const removeMobile = (index) => {
    const newMobiles = inputs.mobiles.filter((mobile, i) => i !== index);
    setInputs({ ...inputs, mobiles: newMobiles });
  };

  const addMobiles = (mobiles) => {
    var phoneRule = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
    const newMobiles = mobiles.filter(
      (mobile) => phoneRule.test(mobile) && !inputs.mobiles.includes(mobile)
    );
    setInputs({ ...inputs, mobiles: [...inputs.mobiles, ...newMobiles] });
  };

  const openAddModal = () => {
    setAddOpen(true);
  };

  const closeAddModal = () => {
    setAddOpen(false);
  };

  const fetch = () => {
    api.fetch({ active: 'Y' }).then((res) => {
      console.log(res);
      setTemplates(res.templates);
    });
  };

  const handleOnSend = () => {
    setRequesting(true);
    api
      .sendMobileMessage(inputs)
      .then((res) => {
        console.log(res);
        messageApi.open({
          type: 'success',
          content: '발송되었습니다.',
        });
        onClose();
      })
      .catch((err) => {
        messageApi.open({
          type: 'error',
          content: '문자발송에 실패했습니다. 관리자에게 문의하세요.',
        });
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const handleClose = () => {
    setInputs(initInputs);
    onClose();
  };

  const templateOptions = templates.map((template) => ({
    value: template.content,
    label: template.name,
  }));

  const handleTemplateChange = (value) => {
    setInputs({ ...inputs, content: value });
  };

  const footer = [
    <Button key="back" onClick={handleClose}>
      취소
    </Button>,
    <Button
      key="submit"
      type="primary"
      loading={false}
      disabled={
        inputs.mobiles.length < 1 ||
        !inputs.content ||
        requesting ||
        countStrLength(inputs.content) > 2000
      }
      onClick={handleOnSend}
    >
      발송
    </Button>,
  ];

  return (
    <Modal
      title="SMS 발송"
      visible={open}
      afterClose={handleClose}
      onCancel={handleClose}
      footer={footer}
      maskClosable={false}
    >
      {contextHolder}
      <Modal
        title="휴대전화번호 추가"
        visible={addOpen}
        onCancel={() => setAddOpen(false)}
        width={300}
        footer={[
          <Button key="backAdd" onClick={closeAddModal}>
            취소
          </Button>,
          <Button
            key="submitAdd"
            type="primary"
            onClick={() => {
              addMobiles(mobileText.split('\n'));
              setMobileText('');
              setAddOpen(false);
            }}
          >
            추가
          </Button>,
        ]}
      >
        <Input.TextArea
          value={mobileText}
          autoSize={{ minRows: 3 }}
          onChange={(e) => setMobileText(e.target.value)}
        />
        <div style={{ marginTop: 16, fontSize: 13, color: 'gray' }}>
          <div>* 한 줄에 하나씩 입력하세요.</div>
          <div>* 01012345678 형식으로 입력하세요.</div>
        </div>
      </Modal>
      <div>
        <Row gutter={16}>
          <Col span={12}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Select
                // defaultValue=""
                value={inputs.content}
                style={{ width: '100%' }}
                onChange={handleTemplateChange}
                options={[
                  { value: '', label: '템플릿 선택하세요' },
                  ...templateOptions,
                ]}
              />
              <Input.TextArea
                value={inputs.content}
                autoSize={{ minRows: 10 }}
                onChange={(e) =>
                  setInputs({ ...inputs, content: e.target.value })
                }
              />
              <div style={{ float: 'right', paddingRight: 16 }}>
                <span>{countStrLength(inputs.content)}</span> / 2000 byte
              </div>
            </Space>
          </Col>
          <Col span={12}>
            <div style={{ marginBottom: 10 }}>
              <span>받는사람: {inputs.mobiles.length}</span>{' '}
              <Button
                type="link"
                icon={<PlusCircleOutlined />}
                onClick={openAddModal}
              />{' '}
            </div>
            <div
              style={{
                height: 225,
                overflowY: 'scroll',
                border: '1px solid lightgray',
                padding: 8,
              }}
            >
              {inputs.mobiles.map((mobile, index) => (
                <div key={index}>
                  <span>{mobile}</span>
                  <Button
                    type="link"
                    icon={<MinusCircleOutlined />}
                    danger
                    onClick={() => removeMobile(index)}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <div style={{ marginTop: 16, fontSize: 13, color: 'gray' }}>
          <div>* 중복된 휴대전화번호는 자동으로 서버에서 제거됩니다.</div>
          <div>
            * 90 byte 이하는 단문메시지로 발송되고, 그 이상은 장문메시지로
            발송됩니다.
          </div>
          <div>* 2000 byte를 초과하면 발송되지 않습니다.</div>
        </div>
      </div>
    </Modal>
  );
};

export default SmsSendModal;

function countStrLength(str) {
  var strLen = str.length;
  var cnt = 0;
  var oneChar = '';

  for (var ii = 0; ii < strLen; ii++) {
    oneChar = str.charAt(ii);
    if (escape(oneChar).length > 4) {
      cnt += 2;
    } else {
      cnt++;
    }
  }
  return cnt;
}
