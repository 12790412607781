import request from 'lib/request';
import axios from 'lib/request/axios';

const storesApi = {
  fetch: (meta) => request.get('/api/stores', meta),
  fetchOne: (store) => request.get(`/api/stores/${store.id}`),
  create: (store) => request.post('/api/stores', store),
  update: (store) => request.put(`/api/stores/${store.id}`, store),
  delete: (store) => request.del(`/api/stores/${store.id}`),
  fetchAll: (meta) =>
    axios({ method: 'get', url: '/api/stores/all', params: meta }),

  getStoreMobiles: (storeId) => request.get(`/api/stores/${storeId}/mobiles`),

  // cashChange: (store, cash, title) =>
  //   request.post(`/api/admin/cash/stores/${store.id}/change`, {
  //     cash: cash,
  //     title: title,
  //   }),
  // storeCashes: (store) => request.get(`/api/stores/${store.id}/cash`),
  fetchGifticons: () => request.get('/api/gifticons/simple'),
  updateStoreGifticon: (store, inputs) =>
    request.put(`/api/stores/${store.id}/gifticon`, inputs),

  deletePayment: (payload) =>
    request.del(`/api/stores/${payload.storeId}/payment/${payload.yearMonth}`),
  addPayment: (payload) =>
    request.post(`/api/stores/${payload.storeId}/payment`, {
      yearMonth: payload.yearMonth,
    }),

  fetchStoreGifticons: (store) =>
    request.get(`/api/stores/${store.id}/gifticons`),
  addStoreGifticon: (store, gifticonCode) =>
    request.post(`/api/stores/${store.id}/gifticons`, {
      gifticonCode: gifticonCode,
    }),
  deleteStoreGifticon: (store, gifticonCode) =>
    request.del(`/api/stores/${store.id}/gifticons/${gifticonCode}`),
};

export default storesApi;
