import React, { useEffect, useState } from 'react';
import api from '../api';
import SmsTemplateItem from './SmsTemplateItem';
import SmsTemplateForm from './SmsTemplateForm';
import { Button, Table } from 'antd';
import format from '../../../lib/format';

const SmsTemplateList = () => {
  const [loading, setLoading] = useState(false);
  const [templates, setTempates] = useState([]);

  const [open, setOpen] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);

  useEffect(() => {
    fetch();
  }, []);

  const handleEdit = (template) => {
    setCurrentTemplate(template);
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentTemplate(null);
    fetch();
    setOpen(false);
  };

  const fetch = () => {
    setLoading(true);
    api
      .fetch()
      .then((res) => {
        console.log(res);
        setTempates(res.templates);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    { title: '템플릿명', dataIndex: 'name', key: 'name' },
    {
      title: '내용',
      dataIndex: 'content',
      key: 'content',
      render: (content) =>
        content.length < 40 ? content : content.substring(0, 35) + '...',
    },
    {
      title: '사용여부',
      dataIndex: 'active',
      key: 'active',
      render: (active) => (
        <span style={{ color: active === 'Y' ? 'green' : 'red' }}>
          {active === 'Y' ? '활성' : '비활성'}
        </span>
      ),
    },
    {
      title: '등록일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => format.date(createdAt),
    },
    {
      title: '수정일',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => format.date(updatedAt),
    },
    {
      title: '수정',
      dataIndex: 'id',
      key: 'id',
      render: (id, template) => (
        <Button type="primary" onClick={() => handleEdit(template)}>
          수정
        </Button>
      ),
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Button type="primary" onClick={() => setOpen(true)}>
          템플릿 추가
        </Button>
      </div>
      {templates && (
        <Table
          loading={loading}
          className="ceosTable"
          columns={columns}
          dataSource={templates}
          rowKey={(template) => template.id}
          bordered
          rowClassName={(template) => (template.active ? 'active-ceo' : '')}
          pagination={false}
        />
      )}
      <SmsTemplateForm
        template={currentTemplate}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
};

export default SmsTemplateList;
