import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import xlsx from 'xlsx'
import { format } from 'date-fns'
import storesApi from 'modules/stores/api'
import message from 'lib/message'
import queryString from 'query-string'

import {
  fetchStores,
  fetchOneStores,
  createStores,
  updateStores,
  deleteStores,
  openModal,
  closeModal,
  removeError,
} from '../../store'
import {
  fetchPhotos,
  deletePhotos,
  closePhotoModal,
  openPhotoModal,
} from '../../store'
import { updateConfirm, updateConfirm2 } from '../../store'
import { openCashModal, closeCashModal, updateCash } from '../../store'
import { openGifticonModal, closeGifticonModal } from '../../store'
import { resetUnreadNotice } from '../../store'

import Page from 'components/Common/Page'
import StoreForm from '../../components/StoreForm'
import StoreList from '../../components/StoreList'
import StoreSearch from '../../components/StoreSearch'
// import StorePhoto from '../../components/StorePhoto'
// import ReturnModal from '../../components/ReturnModal'
import CashModal from '../../components/CashModal'
import GifticonModal from '../../components/GifticonModal'
// import getPhotoText from './getPhotoText'

import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const StoresPage = (props) => {
  const { history, location } = props

  const dispatch = useDispatch()
  const user = useSelector((store) => store.account.user)
  const loading = useSelector((store) => !!store.stores.loading)
  const stores = useSelector((store) => store.stores.stores)
  // const regions = useSelector((store) => store.stores.regions)
  const categories = useSelector((store) => store.stores.categories)
  const search = useSelector((store) => store.stores.search)
  const pagination = useSelector((store) => store.stores.pagination)
  const modal = useSelector((store) => store.stores.modal)
  const cashModal = useSelector((store) => store.stores.cashModal)
  const gifticonModal = useSelector((store) => store.stores.gifticonModal)
  const isAdmin = useSelector(
    (store) => store.account.user && store.account.user.role === 'admin'
  )

  const unreadNotice = useSelector((store) => store.stores.unreadNotice)

  useEffect(() => {
    const _query = queryString.parse(location.search)
    dispatch(fetchStores(_query))
  }, [dispatch, location.search])

  const handleOpen = (store) => {
    store && dispatch(fetchOneStores(store))
    dispatch(openModal())
  }

  const reload = () => {
    dispatch(fetchStores(queryString.parse(location.search)))
  }

  const handleClose = () => {
    reload()
    dispatch(closeModal())
  }

  const handleUpdate = (store) => {
    dispatch(updateStores(store))
    reload()
  }

  const handleCreate = (store) => {
    dispatch(createStores(store))
    reload()
  }

  const handleDelete = (store) => {
    dispatch(deleteStores(store))
    reload()
  }

  const handleGifticonModalOpen = (store) => {
    dispatch(openGifticonModal({ store: store }))
  }

  const handlePageChange = (page) => {
    const _query = { ...queryString.parse(location.search), page: page }
    history.push(`/stores?${queryString.stringify(_query)}`)
  }

  const handleSearch = (_search) => {
    history.push(`/stores?${queryString.stringify(_search)}`)
  }

  const handleExit = () => {
    reload()
  }

  const handleNoticeAlert = () => {
    Modal.confirm({
      title: '공지사항으로 이동하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      content: `읽지 않는 공지사항이 ${unreadNotice}건 있습니다. 반드시 확인해 주십시요.`,
      okText: '이동',
      cancelText: '취소',
      onOk() {
        dispatch(resetUnreadNotice())
        history.push('/notices')
      },
      onCancel() {
        dispatch(resetUnreadNotice())
      },
    })
  }

  const addPayment = (storeId, yearMonth) => {
    // console.log('add payment')
    storesApi
      .addPayment({ storeId, yearMonth })
      .then(() => message.success('납부로 변경되었습니다.'))
      .catch(() => message.error('변경 중 에러가 발생했습니다.'))
      .finally(() => dispatch(fetchStores(queryString.parse(location.search))))
  }

  const deletePayment = (storeId, yearMonth) => {
    // console.log('delete payment')
    storesApi
      .deletePayment({ storeId, yearMonth })
      .then(() => message.success('미납으로 변경되었습니다.'))
      .catch(() => message.error('변경 중 에러가 발생했습니다.'))
      .finally(() => dispatch(fetchStores(queryString.parse(location.search))))
  }

  const handleChangePayment = (storeId, yearMonth, isPayed) => {
    if (isPayed) {
      deletePayment(storeId, yearMonth)
    } else {
      addPayment(storeId, yearMonth)
    }
  }

  const handleCloseGifticonModal = () => {
    dispatch(fetchStores(queryString.parse(location.search)))
    dispatch(closeGifticonModal())
  }

  useEffect(() => {
    unreadNotice > 0 && handleNoticeAlert()
  }, [unreadNotice])

  return (
    <Page>
      <StoreSearch meta={search} handleSearch={handleSearch} />
      <StoreList
        isAdmin={isAdmin}
        loading={loading}
        stores={stores}
        pagination={pagination}
        handleOpen={handleOpen}
        handlePageChange={handlePageChange}
        handleGifticonModalOpen={handleGifticonModalOpen}
        handleSearch={handleSearch}
        handleChangePayment={handleChangePayment}
      />

      <StoreForm
        isAdmin={isAdmin}
        open={modal.open}
        requesting={modal.requesting}
        errors={modal.error && modal.error.errors}
        store={modal.store}
        // regions={regions}
        categories={categories}
        handleUpdate={handleUpdate}
        handleCreate={handleCreate}
        handleDelete={handleDelete}
        handleClose={handleClose}
        // handleExit={handleExit}
        removeError={(payload) => dispatch(removeError(payload))}
      />
      {/* <CashModal
        isAdmin={isAdmin}
        modal={cashModal}
        handleClose={() => dispatch(closeCashModal())}
        handleExit={handleExit}
        handleUpdate={(payload) => dispatch(updateCash(payload))}
      /> */}
      <GifticonModal
        isAdmin={isAdmin}
        modal={gifticonModal}
        handleClose={handleCloseGifticonModal}
        handleExit={handleExit}
      />
    </Page>
  )
}

export default StoresPage
