function makeAsyncActionsTypes(prefix, actionName) {
  return {
    INDEX: `${prefix}/${actionName}/INDEX`,
    REQUEST: `${prefix}/${actionName}/REQUEST`,
    SUCCESS: `${prefix}/${actionName}/SUCCESS`,
    FAIL: `${prefix}/${actionName}/FAIL`,
  };
}

function makeActionCreator(actionType) {
  return (payload) => ({ type: actionType, payload });
}

function makeAsyncActionCreator(actions) {
  let actionCreator = makeActionCreator(actions.INDEX);
  actionCreator.request = makeActionCreator(actions.REQUEST);
  actionCreator.success = makeActionCreator(actions.SUCCESS);
  actionCreator.fail = makeActionCreator(actions.FAIL);
  return actionCreator;
}

export { makeAsyncActionsTypes, makeActionCreator, makeAsyncActionCreator };
