import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import omit from 'lodash/omit'
import message from 'lib/message'
import {
  fetchCeos,
  fetchOneCeos,
  createCeos,
  updateCeos,
  deleteCeos,
  openModal,
  closeModal,
  removeError,
} from '../../store'
import queryString from 'query-string'

import Page from 'components/Common/Page'
import CeoForm from '../../components/CeoForm'
import CeoList from '../../components/CeoList'
import CeoSearch from '../../components/CeoSearch'

import CashModal from '../../components/CashModal'
import { openCashModal, closeCashModal, updateCash } from '../../store'

import StoreForm from 'modules/stores/components/StoreForm'
import {
  openModal as openStoreModal,
  closeModal as closeStoreModal,
  createStores,
  updateStores,
  deleteStores,
  removeError as removeStoreError,
} from 'modules/stores/store'

const CeosPage = (props) => {
  const { history, location } = props

  const dispatch = useDispatch()
  const loading = useSelector((store) => !!store.ceos.loading)
  const ceos = useSelector((store) => store.ceos.ceos)
  const meta = useSelector((store) => store.ceos.meta)
  const modal = useSelector((store) => store.ceos.modal)
  const cashModal = useSelector((store) => store.ceos.cashModal)
  const pagination = useSelector((store) => store.ceos.pagination)
  const isAdmin = useSelector(
    (store) => store.account.user && store.account.user.role === 'admin'
  )

  const storeModal = useSelector((store) => store.stores.modal)

  useEffect(() => {
    const _query = queryString.parse(location.search)
    dispatch(fetchCeos(_query))
  }, [dispatch, location.search])

  const handleOpen = (ceo) => {
    ceo && dispatch(fetchOneCeos(ceo))
    dispatch(openModal())
  }

  const handlePageChange = (page) =>
    dispatch(fetchCeos(omit({ ...meta, page: page }, 'total')))
  // const handleSearch = (search) => dispatch(fetchCeos({ ...meta, ...search }))

  const handleCeoStores = (meta) => {
    if (meta.ceo && meta.ceo === 'self') {
      history.push(`/stores`)
      return
    } else {
      const text = queryString.stringify(meta)
      history.push(`/stores?${text}`)
      return
    }
  }

  const handleCashModalOpen = (ceo) => {
    dispatch(openCashModal({ ceo: ceo }))
  }

  const handleExit = () => {
    dispatch(fetchCeos(queryString.parse(location.search)))
  }

  const handleStoreModalOpen = (ceo) => {
    dispatch(openStoreModal(ceo.id))
  }

  const handleSearch = (_search) => {
    history.push(`/ceos?${queryString.stringify(_search)}`)
  }

  return (
    <Page>
      <CeoSearch meta={meta} handleSearch={handleSearch} />
      <CeoList
        isAdmin={isAdmin}
        loading={loading}
        meta={meta}
        data={ceos}
        pagination={pagination}
        handleOpen={handleOpen}
        handlePageChange={handlePageChange}
        handleCeoStores={handleCeoStores}
        handleCashModalOpen={handleCashModalOpen}
        handleStoreModalOpen={handleStoreModalOpen}
      />
      <CeoForm
        open={modal.open}
        requesting={modal.requesting}
        errors={modal.error && modal.error.errors}
        ceo={modal.ceo}
        handleUpdate={(ceo) => dispatch(updateCeos(ceo))}
        handleCreate={(ceo) => dispatch(createCeos(ceo))}
        handleDelete={(ceo) => dispatch(deleteCeos(ceo))}
        handleClose={() => dispatch(closeModal())}
        handleExit={() => dispatch(fetchCeos(meta))}
        removeError={(payload) => dispatch(removeError(payload))}
      />
      <CashModal
        isAdmin={isAdmin}
        modal={cashModal}
        handleClose={() => dispatch(closeCashModal())}
        handleExit={handleExit}
        handleUpdate={(payload) => dispatch(updateCash(payload))}
      />{' '}
      <StoreForm
        isAdmin={isAdmin}
        open={storeModal.open}
        requesting={storeModal.requesting}
        ceoId={storeModal.ceoId}
        errors={storeModal.error && storeModal.error.errors}
        store={storeModal.store}
        handleUpdate={(store) => dispatch(updateStores(store))}
        handleCreate={(store) => dispatch(createStores(store))}
        handleDelete={(store) => dispatch(deleteStores(store))}
        handleClose={() => dispatch(closeStoreModal())}
        handleExit={() => dispatch(fetchCeos(meta))}
        removeError={(payload) => dispatch(removeStoreError(payload))}
      />
    </Page>
  )
}

export default CeosPage
