import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'antd'
import {
  Form,
  Input,
  Row,
  Col,
  Table,
  Descriptions,
  Typography,
  Select,
} from 'antd'
import api from '../../api'
import message from 'lib/message'
import format from 'lib/format'

const { Text, Link } = Typography
const { Option } = Select
// const apiUrl = process.env.REACT_APP_API_URL

const initialCash = {
  type: 'add',
  cash: '',
  title: '',
}

const CashModal = (props) => {
  const { isAdmin, modal, removeError, search } = props
  const { handleClose, handleExit, handleUpdate } = props
  const { open, requesting, errors, ceo } = modal

  const [inputs, setInputs] = useState(initialCash)
  const [loading, setLoading] = useState(false)
  const [cashes, setCashes] = useState([])
  const [currentCeo, setCurrentCeo] = useState(ceo)

  useEffect(() => {
    if (ceo) fetchCashes()
    if (ceo) fetchCeo()
    return () => {
      setInputs(initialCash)
    }
  }, [ceo])

  const fetchCeo = () => {
    api.fetchOne(ceo).then((response) => {
      setCurrentCeo(response.ceo)
    })
  }

  const fetchCashes = () => {
    setLoading(true)
    api
      .fetchCashes(ceo)
      .then((response) => {
        setCashes(response.cashes)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const createCash = () => {
    if (!inputs.cash) return false
    setLoading(true)
    api
      .updateCash(ceo, inputs.type === 'minus' ? inputs.cash * -1 : inputs.cash)
      .then((response) => {
        message.success(
          inputs.type === 'add' ? '충전되었습니다.' : '차감되었습니다.'
        )
        setInputs(initialCash)
      })
      .catch((error) => {
        message.error(
          inputs.type === 'add' ? '충전에러입니다.' : '차감에러입니다.'
        )
      })
      .finally(() => {
        fetchCashes()
        fetchCeo()
        handleExit()
        setLoading(false)
      })
  }

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
    removeErrorWithName(errors, e.target.name)
  }

  const handleCashChange = (e) => {
    const { value: inputValue } = e.target
    const reg = /^-?\d*(\.\d*)?$/

    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      setInputs({ ...inputs, cash: inputValue })
    }
  }

  const removeErrorWithName = (errors, name) => {
    errors && errors[name] && removeError({ name })
  }

  const footer = [
    <Button
      key="cancle"
      onClick={() => {
        handleClose()
        handleExit()
      }}
      disabled={loading}
    >
      닫기
    </Button>,
  ]

  const handleChangeCashType = (value) => setInputs({ ...inputs, type: value })

  const cashInputBefore = (
    <Select
      value={inputs.type}
      style={{ width: 60 }}
      onChange={handleChangeCashType}
    >
      <Option value="add">+</Option>
      <Option value="minus">-</Option>
    </Select>
  )

  if (!currentCeo) return ''

  return (
    <Modal
      title={
        <span>
          <span style={{ fontWeight: 'bold', color: 'blue' }}>
            {currentCeo.username}
          </span>{' '}
          충전금 관리
        </span>
      }
      visible={open}
      afterClose={handleExit}
      onCancel={handleClose}
      footer={footer}
      maskClosable={false}
      width={600}
    >
      <div>
        {isAdmin && (
          <Form layout="vertical">
            <Row gutter={16}>
              <Col>
                <div style={{ paddingTop: 2 }}>
                  <span
                    style={{
                      fontSize: 16,
                      color: 'gray',
                      fontWeight: 'bold',
                    }}
                  >
                    잔액{' '}
                    <span style={{ color: 'red' }}>
                      {currentCeo.cash
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                    </span>
                    원
                  </span>
                </div>
              </Col>
              <Col flex="1 1 100px">
                <Form.Item
                  validateStatus={errors && errors.storeId && 'error'}
                  help={errors && errors.storeId && errors.storeId[0]}
                >
                  <Input
                    name="cash"
                    addonBefore={cashInputBefore}
                    value={inputs.cash}
                    onChange={handleCashChange}
                    disabled={loading}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  type="primary"
                  danger={inputs.type === 'minus'}
                  disabled={loading}
                  onClick={createCash}
                >
                  {inputs.cash
                    ? inputs.cash
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : 0}
                  원 {inputs.type === 'add' ? '충전' : '차감'}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
        <Table
          size="small"
          // scroll={{
          //   y: 400,
          // }}
          columns={[
            // {
            //   title: 'DATE',
            //   dataIndex: 'createdAt',
            //   key: 'createdAt',
            //   render: (createdAt) => (
            //     <Text style={{ fontSize: 12 }}>
            //       {format.datetime(createdAt)}
            //     </Text>
            //   ),
            // },
            {
              title: 'TITLE(변동내역)',
              dataIndex: 'title',
              key: 'title',
              render: (title, ceoCash) => (
                <>
                  <div>
                    <Text style={{ fontSize: 12 }}>{title}</Text>
                  </div>
                  <div>
                    <Text style={{ fontSize: 12 }}>
                      {format.datetime(ceoCash.createdAt)}
                    </Text>
                  </div>
                </>
              ),
            },
            {
              title: '금액',
              dataIndex: 'cash',
              key: 'cash',
              align: 'center',
              render: (cash) => (
                <Text>
                  <span
                    style={{
                      color: cash > 0 ? 'blue' : 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    {cash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                  원
                </Text>
              ),
            },
            {
              title: '잔액',
              dataIndex: 'ceoCash',
              key: 'ceoCash',
              align: 'center',
              render: (ceoCash) => (
                <Text style={{ fontWeight: 'bold' }}>
                  {ceoCash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                </Text>
              ),
            },
          ]}
          dataSource={cashes}
          rowKey={(ceo) => ceo.id}
          loading={loading}
          pagination={true}
        />
      </div>
    </Modal>
  )
}

export default CashModal
