import { takeLatest, takeEvery, all, call, put } from 'redux-saga/effects'
import { actionTypes } from './store'
import {
  fetchStores,
  createStores,
  fetchOneStores,
  updateStores,
  deleteStores,
  closeModal,
  fetchPhotos,
  deletePhotos,
  updateReturn,
  updateConfirm,
  updateConfirm2,
  closeReturnModal,
} from './store'
import api from './api'
import apiSaga from 'lib/saga/apiSaga'
import message from 'lib/message'

const {
  FETCH_STORES,
  CREATE_STORES,
  FETCH_ONE_STORES,
  UPDATE_STORES,
  DELETE_STORES,
  FETCH_PHOTOS,
  DELETE_PHOTOS,
  UPDATE_RETURN,
  UPDATE_CONFIRM,
  UPDATE_CONFIRM2,
} = actionTypes

/**************************************************************************************************
  가맹점 모달
**************************************************************************************************/
// 1. fetch
export function* fetchStoresSaga(action) {
  yield call(apiSaga, api.fetch, fetchStores, { apiPayload: action.payload })
}

// 2. create
export function* createStoresSaga(action) {
  const success = yield call(apiSaga, api.create, createStores, {
    apiPayload: action.payload,
  })
  if (success) {
    yield put(closeModal())
    message.success('가맹점이 생성되었습니다.')
  } else {
    message.error('입력내용을 확인해주세요')
  }
}

// 3. fetchOne
export function* fetchOneStoresSaga(action) {
  yield call(apiSaga, api.fetchOne, fetchOneStores, {
    apiPayload: action.payload,
  })
}

// 4. update
export function* updateStoresSaga(action) {
  const success = yield call(apiSaga, api.update, updateStores, {
    apiPayload: action.payload,
  })
  if (success) {
    yield put(closeModal())
    message.success('가맹점이 수정되었습니다.')
  } else {
    message.error('입력내용을 확인해주세요')
  }
}

// 5. delete
export function* deleteStoresSaga(action) {
  const success = yield call(apiSaga, api.delete, deleteStores, {
    apiPayload: action.payload,
  })
  if (success) {
    yield put(closeModal())
    message.success('가맹점이 삭제되었습니다.')
  } else {
    message.error('삭제중 에러가 발생했습니다.')
  }
}
/**************************************************************************************************
  사진 모달
**************************************************************************************************/
// 21. fetch
export function* fetchPhotosSaga(action) {
  yield call(apiSaga, api.photoFetch, fetchPhotos, {
    apiPayload: action.payload,
  })
}

export function* deletePhotosSaga(action) {
  const success = yield call(apiSaga, api.photoDelete, deletePhotos, {
    apiPayload: action.payload,
  })
  if (success) {
    // yield put(closeModal())
    message.success('사진이 삭제되었습니다.')
  } else {
    message.error('삭제중 에러가 발생했습니다.')
  }
}
/**************************************************************************************************
  반려 모달
**************************************************************************************************/
export function* updateReturnSaga(action) {
  const success = yield call(apiSaga, api.updateReturn, updateReturn, {
    apiPayload: action.payload,
  })
  if (success) {
    yield put(closeReturnModal())
    message.success('수정되었습니다.')
    yield put(fetchStores(action.payload.search))
  } else {
    message.error('입력내용을 확인해주세요')
  }
}

/**************************************************************************************************
  검수 모달
**************************************************************************************************/
export function* updateConfirmSaga(action) {
  const success = yield call(apiSaga, api.updateConfirm, updateConfirm, {
    apiPayload: action.payload,
  })
  if (success) {
    message.success('변경되었습니다.')
    yield put(fetchStores(action.payload.search))
  } else {
    message.error('변경되지 않았습니다.')
  }
}

/**************************************************************************************************
  승인 모달
**************************************************************************************************/
export function* updateConfirm2Saga(action) {
  const success = yield call(apiSaga, api.updateConfirm2, updateConfirm2, {
    apiPayload: action.payload,
  })
  if (success) {
    message.success('변경되었습니다.')
    yield put(fetchStores(action.payload.search))
  } else {
    message.error('변경되지 않았습니다.')
  }
}

export default function* storesSagas() {
  yield all([
    takeEvery(FETCH_STORES.INDEX, fetchStoresSaga),
    takeLatest(CREATE_STORES.INDEX, createStoresSaga),
    takeEvery(FETCH_ONE_STORES.INDEX, fetchOneStoresSaga),
    takeLatest(UPDATE_STORES.INDEX, updateStoresSaga),
    takeLatest(DELETE_STORES.INDEX, deleteStoresSaga),

    takeEvery(FETCH_PHOTOS.INDEX, fetchPhotosSaga),
    takeLatest(DELETE_PHOTOS.INDEX, deletePhotosSaga),

    takeLatest(UPDATE_RETURN.INDEX, updateReturnSaga),

    takeLatest(UPDATE_CONFIRM.INDEX, updateConfirmSaga),
    takeLatest(UPDATE_CONFIRM2.INDEX, updateConfirm2Saga),
  ])
}
