import React, { useEffect, useState } from 'react'
import { Table, Button, AutoComplete, Space } from 'antd'
import ActiveStatus from 'components/Common/ActiveStatus'
import format from 'lib/format'
import { LoginOutlined, EditOutlined } from '@ant-design/icons'
import _ from 'lodash'

const CeoList = ({
  loading,
  isAdmin,
  data,
  pagination,
  meta,
  handleOpen,
  handleAdd,
  handlePageChange,
  forceLogin,
  handleCeoStores,
  handleCashModalOpen,
  handleStoreModalOpen,
}) => {
  useEffect(() => {}, [])

  const displayStoreCount = (ceo) => (
    <span>
      <Button
        type="link"
        onClick={() =>
          handleCeoStores({
            ceoId: ceo.id,
          })
        }
      >
        {ceo.storeCount}
      </Button>
    </span>
  )

  const displayCeoName = (ceo) => {
    return (
      <span>
        <span style={{ fontWeight: 'bold' }}>{ceo.username}</span>
      </span>
    )
  }

  const columns = [
    {
      title: '대리점',
      dataIndex: 'agencyName',
      key: 'agencyName',
    },
    {
      title: '매장사장님',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (text, ceo) => displayCeoName(ceo),
    },
    { title: '매장사장님ID', dataIndex: 'loginId', key: 'loginid' },
    {
      title: '매장사장님 휴대폰',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (mobile) => format.phone(mobile),
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (date) => format.date(date),
    },
    {
      title: '매장수',
      dataIndex: 'storeCount',
      key: 'storeCount',
      render: (storeCount, ceo) => displayStoreCount(ceo),
    },
    {
      title: '충전금',
      dataIndex: 'cash',
      key: 'cash',
      render: (cash, ceo) => {
        const cashNum = cash
          ? cash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : 0
        return (
          <Button type="link" onClick={() => handleCashModalOpen(ceo)}>
            {cashNum}
          </Button>
        )
      },
    },
    {
      title: '활성',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active) => (
        <span style={{ color: active === 'Y' ? 'green' : 'red' }}>
          {active === 'Y' ? '활성' : '비활성'}
        </span>
      ),
    },
    {
      title: '관리',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (value, ceo, index) => (
        <Space>
          {isAdmin && (
            <Button onClick={() => handleStoreModalOpen(ceo)}>매장등록</Button>
          )}
          <Button danger onClick={() => handleOpen(ceo)} disabled={!isAdmin}>
            수정
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <div>
      {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {isAdmin && (
          <>
            <Button
              onClick={() => handleOpen()}
              type="primary"
              style={{ marginBottom: 16 }}
            >
              사장님 등록
            </Button>
          </>
        )}
      </div> */}
      <Table
        loading={loading}
        className="ceosTable"
        columns={columns}
        dataSource={data}
        rowKey={(ceo) => ceo.id}
        bordered
        rowClassName={(ceo) => (ceo.active ? 'active-ceo' : '')}
        pagination={{
          current: pagination.current_page,
          pageSize: pagination.per_page,
          total: pagination.total,
          onChange: handlePageChange,
          showSizeChanger: false,
        }}
      />
    </div>
  )
}

export default CeoList
