const prefix = process.env.REACT_APP_TOKEN_PREFIX
export const getToken = () => {
  return localStorage.getItem(`${prefix}tkn`)
}

export const setToken = (token) => {
  return localStorage.setItem(`${prefix}tkn`, token)
}

export const removeToken = () => {
  return localStorage.removeItem(`${prefix}tkn`)
}

export const getRemember = () => {
  return localStorage.getItem(`${prefix}rmb`)
}

export const setRemember = () => {
  return localStorage.setItem(`${prefix}rmb`, true)
}

export const removeRemember = () => {
  return localStorage.removeItem(`${prefix}rmb`)
}
