import React from 'react'
import { Provider } from 'react-redux'
// import { BrowserRouter as Router } from 'react-router-dom'
import { Router } from 'react-router-dom'
import history from 'lib/history'

import Routes from './Routes'
import store from './store'

import 'antd/dist/antd.css'
import 'antd-button-color/dist/css/style.css'
import './app.css'

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Routes />
      </Router>
    </Provider>
  )
}

export default App
