import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { Form, Input, Switch } from 'antd';
import api from '../api';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const initTemplate = {
  name: '',
  content: '',
  active: 'Y',
};

const SmsTemplateForm = ({ template, open, onClose }) => {
  const [inputs, setInputs] = useState(initTemplate);
  const [requesting, setRequesting] = useState(false);

  useEffect(() => {
    template && template.id && fetchOne();
  }, [template]);

  const handleClose = () => {
    setInputs(initTemplate);
    onClose();
  };

  const fetchOne = () => {
    api
      .fetchOne(template)
      .then((res) => {
        setInputs(res.template);
      })
      .catch((err) => {
        console.log(err);
        onClose();
      });
  };

  const handleOnCreate = () => {
    if (!inputs.name) {
      alert('템플릿명을 입력해주세요.');
      return false;
    }
    if (inputs.name.length > 50) {
      alert('템플릿명은 50자 이하로 입력해주세요.');
      return false;
    }

    if (!inputs.content) {
      alert('내용을 입력해주세요.');
      return false;
    }

    if (countStrLength(inputs.content) > 2000) {
      alert('내용은 2000byte 이하로 입력해주세요.');
      return false;
    }

    setRequesting(true);
    api
      .create(inputs)
      .then((res) => {
        console.log(res);
        onClose();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const handleOnUpdate = () => {
    setRequesting(true);
    api
      .update(inputs)
      .then((res) => {
        console.log(res);
        onClose();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const handleDelete = () => {
    setRequesting(true);
    api
      .delete(inputs)
      .then((res) => {
        console.log(res);
        onClose();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const handleActiveChange = (value) => {
    setInputs({ ...inputs, active: value ? 'Y' : 'N' });
  };

  const handleNameChange = (e) => {
    setInputs({ ...inputs, name: e.target.value });
  };

  const handleContentChange = (e) => {
    setInputs({ ...inputs, content: e.target.value });
  };

  const handleDeleteConfirm = () => {
    Modal.confirm({
      title: '정말로 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      content: '삭제하면 데이터를 복구할수 없습니다.',
      okText: '삭제',
      cancelText: '취소',
      onOk() {
        handleDelete(inputs);
      },
    });
  };

  const updateFooter = [
    <Button
      key="delete"
      type="primary"
      danger
      onClick={handleDeleteConfirm}
      disabled={requesting}
    >
      삭제
    </Button>,
    <Button key="back" onClick={handleClose}>
      취소
    </Button>,
    <Button
      key="submit"
      type="primary"
      loading={false}
      onClick={handleOnUpdate}
    >
      수정
    </Button>,
  ];

  const createFooter = [
    <Button key="back" onClick={handleClose}>
      취소
    </Button>,
    <Button
      key="submit"
      type="primary"
      loading={false}
      onClick={handleOnCreate}
    >
      등록
    </Button>,
  ];

  return (
    <Modal
      title={inputs.id ? '템플릿 수정' : '템플릿 등록'}
      visible={open}
      afterClose={onClose}
      onCancel={handleClose}
      footer={inputs.id ? updateFooter : createFooter}
      maskClosable={false}
    >
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        initialValues={inputs}
      >
        <Form.Item
          label="템플릿명"
          rules={[{ required: true, message: '템플릿명을 입력해주세요.' }]}
        >
          <Input name="name" value={inputs.name} onChange={handleNameChange} />
        </Form.Item>
        <Form.Item
          label="내용"
          rules={[{ required: true, message: '내용을 입력해주세요.' }]}
        >
          <Input.TextArea
            autoSize={true}
            name="content"
            value={inputs.content}
            onChange={handleContentChange}
          />
          <div>
            <span>{countStrLength(inputs.content)}</span> / 2000
          </div>
        </Form.Item>
        <Form.Item label="사용여부">
          <Switch
            name="active"
            checked={inputs.active === 'Y'}
            onChange={handleActiveChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SmsTemplateForm;
// sms 90byte 제한
// lms 2000byte 제한
// 인코딩에 관계없이 한글 2바이트, 영문 1바이트로 계산
function countStrLength(str) {
  var strLen = str.length;
  var cnt = 0;
  var oneChar = '';

  for (var ii = 0; ii < strLen; ii++) {
    oneChar = str.charAt(ii);
    if (escape(oneChar).length > 4) {
      cnt += 2;
    } else {
      cnt++;
    }
  }
  return cnt;
}
