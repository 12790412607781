import React, { useState, useEffect } from 'react'
import { Button, Popover, Table, Space, Image, Modal, message } from 'antd'
import api from '../../api'
import format from 'lib/format'
import queryString from 'query-string'
import {
  FileImageTwoTone,
  MessageTwoTone,
  ExclamationCircleOutlined,
  CommentOutlined,
} from '@ant-design/icons'
import ReviewSearch from 'modules/reviews/components/ReviewSearch'
import SmsSendModal from 'modules/sms/components/SmsSendModal'
import MoneyLogModal from 'modules/reviews/components//MoneyLogModal'

const apiUrl = process.env.REACT_APP_API_URL

const initialPreview = {
  src: null,
  visible: false,
}

const ReviewList = (props) => {
  const { history, location } = props

  const { isAdmin } = props
  const [loading, setLoading] = useState(false)
  const [reviews, setReviews] = useState([])
  const [pagination, setPagenation] = useState(null)
  const [preview, setPreview] = useState(initialPreview)
  const [messageOpen, setMessageOpen] = useState(false)

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [smsModalOpen, setSmsModalOpen] = useState(false)
  const [smsMobiles, setSmsMobiles] = useState([])

  const [moneyLogModalOpen, setMoneyLogModalOpen] = useState(false)
  const [moneyLogReview, setMoneyLogReview] = useState(null)

  const handleSmsModalOpen = () => {
    setSmsModalOpen(true)
    setSmsMobiles(selectedItems.map((item) => item.mobile))
  }

  const handleSmsModalClose = () => {
    setSmsModalOpen(false)
    setSmsMobiles([])
    setSelectedRowKeys([])
    setSelectedItems([])
  }

  const handleSmsSingleModalOpen = (mobile) => {
    setSmsModalOpen(true)
    setSmsMobiles([mobile])
  }

  const handleMoneyModalOpen = (review) => {
    setMoneyLogModalOpen(true)
    setMoneyLogReview(review)
  }

  const handleMoneyModalClose = () => {
    setMoneyLogModalOpen(false)
    setMoneyLogReview(null)
    const _query = queryString.parse(location.search)
    fetch(_query)
  }

  useEffect(() => {
    const _query = queryString.parse(location.search)
    fetch(_query)
  }, [location.search])

  const handleSearch = (meta) => {
    history.push(`${location.pathname}?${queryString.stringify(meta)}`)
  }

  const fetch = (meta) => {
    setLoading(true)
    api
      .fetch(meta)
      .then((response) => {
        // console.log(response)
        setReviews(response.data)
        setPagenation({
          page: response.current_page,
          total: response.total,
          lastPage: response.last_page,
          perPage: response.per_page,
        })
      })
      .catch((error) => {
        // console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handlePageChange = (page) => {
    const _query = { ...queryString.parse(location.search), page: page }
    history.push(`${location.pathname}?${queryString.stringify(_query)}`)
  }

  const handleSend = (review) => {
    // console.log(review)
    Modal.confirm({
      title: '상품권을 발송하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okText: '발송',
      cancelText: '취소',
      onOk: () => {
        api
          .sendGifticon(review)
          .then(() => {
            message.success('발송하였습니다.')
          })
          .catch((error) => {
            error.message && message.error(error.message)
          })
          .finally(() => {
            const _query = queryString.parse(location.search)
            fetch(_query)
          })
      },
      onCancel: () => {
        const _query = queryString.parse(location.search)
        fetch(_query)
        // console.log('취소!')
      },
    })
  }

  const handleResend = (review) => {
    // console.log(review)
    Modal.confirm({
      title: '상품권을 재발송하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okText: '재발송하기',
      cancelText: '창닫기',
      onOk: () => {
        api
          .resendGifticon(review)
          .then(() => {
            message.success('재발송하였습니다.')
          })
          .catch((error) => {
            error.message && message.error(error.message)
          })
          .finally(() => {
            const _query = queryString.parse(location.search)
            fetch(_query)
          })
      },
      onCancel: () => {
        const _query = queryString.parse(location.search)
        fetch(_query)
      },
    })
  }

  const handleCancel = (review) => {
    // console.log(review)
    Modal.confirm({
      title: '상품권을 취소하시겠습니까?',
      content: (
        <div>
          상품권을 취소하면 다시 발송할 수가 없습니다.
          <br />꼭 필요한 경우만 사용하시길 바랍니다.
        </div>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: '발송취소하기',
      cancelText: '창닫기',
      onOk: () => {
        api
          .cancelGifticon(review)
          .then(() => {
            message.success('취소하였습니다.')
          })
          .catch((error) => {
            error.message && message.error(error.message)
          })
          .finally(() => {
            const _query = queryString.parse(location.search)
            fetch(_query)
          })
      },
      onCancel: () => {
        const _query = queryString.parse(location.search)
        fetch(_query)
      },
    })
  }

  const handleDeleteMoneyLog = (review) => {
    Modal.confirm({
      title: '현급지급을 취소하시겠습니까?',
      // content: (
      //   <div>
      //     상품권을 취소하면 다시 발송할 수가 없습니다.
      //     <br />꼭 필요한 경우만 사용하시길 바랍니다.
      //   </div>
      // ),
      icon: <ExclamationCircleOutlined />,
      okText: '취소하기',
      cancelText: '창닫기',
      onOk: () => {
        api
          .deleteMoneyLog(review)
          .then(() => {
            message.success('적용하였습니다.')
          })
          .catch((error) => {
            error.message && message.error(error.message)
          })
          .finally(() => {
            const _query = queryString.parse(location.search)
            fetch(_query)
          })
      },
      onCancel: () => {
        const _query = queryString.parse(location.search)
        fetch(_query)
      },
    })
  }

  const columns = [
    {
      title: '대리점',
      dataIndex: 'agencyName',
      key: 'agencyName',
      render: (agencyName, review) => (
        <Button
          type="link"
          onClick={() =>
            history.push(
              `${location.pathname}?agencyId=${review.agencyId}&page=1`
            )
          }
        >
          {agencyName}
        </Button>
      ),
    },
    {
      title: '사장님',
      dataIndex: 'ceoName',
      key: 'ceoName',
      // render: (ceoName, review) => (
      //   <Button
      //     type="link"
      //     onClick={() =>
      //       history.push(`${location.pathname}?ceoId=${review.ceoId}`)
      //     }
      //   >
      //     {ceoName}
      //   </Button>
      // ),
    },
    {
      title: '매장',
      dataIndex: 'storeName',
      key: 'storeName',
      render: (storeName, review) => {
        return (
          <Button
            type="link"
            onClick={() =>
              history.push(
                `${location.pathname}?storeId=${review.storeId}&page=1`
              )
            }
          >
            <span>
              <span>{storeName}</span>
              {review.storeActive === 'Y' ? (
                <span style={{ color: 'green' }}>(발송)</span>
              ) : (
                <span style={{ color: 'red' }}>(중지)</span>
              )}
            </span>
          </Button>
        )
      },
    },
    {
      title: '고객',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (mobile, review) => (
        <>
          <Button
            type="link"
            danger={review.isNew === 'N'}
            onClick={() =>
              history.push(`${location.pathname}?mobile=${mobile}&page=1`)
            }
          >
            {format.phone(mobile)}
          </Button>
          <Button
            shape="circle"
            icon={<CommentOutlined />}
            onClick={() => handleSmsSingleModalOpen(mobile)}
          />
        </>
      ),
    },
    {
      title: '상품권',
      dataIndex: 'gifticonName',
      key: 'gifticonName',
      render: (gifticonName, review) => (
        <span>
          {gifticonName} ( {review.gifticonPrice}원 )
        </span>
      ),
    },
    {
      title: '적립금',
      dataIndex: 'ceoCash',
      key: 'ceoCash',
      render: (ceoCash, review) => {
        const cashNum = ceoCash
          ? ceoCash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : 0
        return <span>{cashNum}원</span>
      },
    },
    {
      title: '사진/메시지',
      dataIndex: 'image',
      key: 'image',
      align: 'center',
      render: (image, review) => (
        <>
          {review.images &&
            review.images.length > 0 &&
            review.images.map((img, index) => (
              <span
                key={index}
                style={{ fontSize: 24, cursor: 'pointer' }}
                onClick={() =>
                  setPreview({
                    ...preview,
                    src: apiUrl + img.path,
                    visible: true,
                  })
                }
              >
                <FileImageTwoTone />
              </span>
            ))}
          {review.message && (
            <Popover
              content={review.message}
              trigger="click"
              open={messageOpen}
              onOpenChange={(newOpen) => setMessageOpen(newOpen)}
            >
              <span
                style={{ fontSize: 24, cursor: 'pointer', marginLeft: 10 }}
                onClick={() => setMessageOpen(true)}
              >
                <MessageTwoTone />
              </span>
            </Popover>
          )}
        </>
      ),
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      // render: (date) => format.date(date),
    },
    {
      title: '변경일',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'center',
      // render: (date) => format.date(date),
    },
    {
      title: '상태',
      dataIndex: 'done',
      key: 'done',
      align: 'center',
      render: (done) => {
        let color
        if (done === 'Y') color = 'green'
        if (done === 'N') color = 'red'
        if (done === 'C') color = 'gray'
        if (done === 'E') color = 'blue'

        return (
          <span style={{ color: color }}>
            {done === 'Y' && '발송'}
            {done === 'N' && '대기'}
            {done === 'C' && '취소'}
            {done === 'E' && '에러'}
          </span>
        )
      },
    },
    {
      title: '현금',
      dataIndex: 'direct_money',
      key: 'direct_money',
      align: 'center',
      render: (direct_money, review) => {
        if (direct_money > 0 && review.done === 'Y') {
          const cashNum = direct_money
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

          return (
            <Button
              danger
              onClick={() => handleDeleteMoneyLog(review)}
              disabled={!isAdmin}
            >
              {cashNum}
            </Button>
          )
        }

        if (review.done !== 'Y') {
          return (
            <Button
              type="primary"
              onClick={() => handleMoneyModalOpen(review)}
              disabled={!isAdmin}
            >
              지급
            </Button>
          )
        }

        return (
          <Button type="primary" disabled={true}>
            지급
          </Button>
        )
      },
    },
    {
      title: '상품권',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (value, review) => {
        if (review.direct_money > 0 && review.done === 'Y') {
          return (
            <Button type="primary" disabled>
              발송
            </Button>
          )
        }

        if (review.done === 'Y') {
          return (
            <Space>
              <Button
                danger
                onClick={() => handleCancel(review)}
                disabled={!isAdmin}
              >
                취소
              </Button>
              <Button onClick={() => handleResend(review)} disabled={!isAdmin}>
                재발송
              </Button>
            </Space>
          )
        }

        return (
          <Button
            type="primary"
            onClick={() => handleSend(review)}
            disabled={!isAdmin}
          >
            발송
          </Button>
        )
      },
    },
  ]

  const rowSelection = {
    type: 'checkbox',
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
      setSelectedItems(selectedRows)
    },
    selectedRowKeys: selectedRowKeys,
  }

  return (
    <>
      <ReviewSearch
        meta={queryString.parse(location.search)}
        handleSearch={handleSearch}
      />
      <div>
        {reviews && pagination && (
          <Table
            rowSelection={rowSelection}
            loading={loading}
            className="ceosTable"
            columns={columns}
            dataSource={reviews}
            rowKey={(review) => review.id}
            bordered
            rowClassName={(ceo) => (ceo.active ? 'active-ceo' : '')}
            pagination={{
              current: pagination.page,
              pageSize: pagination.per_page,
              total: pagination.total,
              onChange: handlePageChange,
              showSizeChanger: false,
            }}
          />
        )}
        <Image
          src={preview.src}
          style={{ display: 'none' }}
          preview={{
            visible: preview.visible,
            src: preview.src,
            onVisibleChange: (value) => {
              setPreview({
                ...preview,
                visible: value,
                src: value === false ? null : preview.src,
              })
            },
          }}
        />

        <div>
          <Button
            type="primary"
            onClick={handleSmsModalOpen}
            disabled={selectedRowKeys.length < 1}
          >
            문자메시지 발송({selectedRowKeys.length})
          </Button>
        </div>
        <SmsSendModal
          open={smsModalOpen}
          onClose={handleSmsModalClose}
          mobiles={smsMobiles}
        />
      </div>
      <MoneyLogModal
        open={moneyLogModalOpen}
        onClose={handleMoneyModalClose}
        addMoneyLog={api.addMoneyLog}
        review={moneyLogReview}
      />
    </>
  )
}

export default ReviewList
