import React, { useState } from 'react'
import { Modal, Form, Input, Button, message } from 'antd'

const initInputs = {
  direct_money: 2000,
}

const MoneyLogModal = (props) => {
  const { open, onClose, addMoneyLog, review } = props
  const [inputs, setInputs] = useState(initInputs)
  const [requesting, setRequesting] = useState(false)

  const handleClose = () => {
    setInputs(initInputs)
    onClose()
  }

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }

  const handleAddMoneyLog = () => {
    if (inputs.direct_money < 100) {
      message.error('100원 이상으로 입력해주세요.')
      return
    }

    setRequesting(true)

    addMoneyLog(review, inputs.direct_money)
      .then(() => {
        message.success('적용하였습니다.')
        onClose()
      })
      .catch((error) => {
        error.message && message.error(error.message)
      })
      .finally(() => {
        setRequesting(false)
      })
  }

  const footer = [
    <Button key="back" onClick={handleClose}>
      취소
    </Button>,
    <Button
      key="submit"
      type="primary"
      loading={false}
      disabled={requesting}
      onClick={handleAddMoneyLog}
    >
      적용
    </Button>,
  ]

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  return (
    <Modal
      title="현급지급 설정"
      visible={open}
      afterClose={handleClose}
      onCancel={handleClose}
      footer={footer}
      maskClosable={false}
    >
      <div>
        <Form {...layout} style={{ maxWidth: 360 }}>
          <Form.Item label="금액">
            <Input
              name="direct_money"
              addonBefore="₩"
              value={inputs.direct_money}
              onChange={handleInputChange}
              disabled={requesting}
              style={{ width: '100%' }}
              allowClear
              type="number"
            />
          </Form.Item>
        </Form>
      </div>
      <div>
        <div style={{ marginTop: 16, fontSize: 13, color: 'gray' }}>
          <div>* 100원이상으로 숫자만 입력해주세요.</div>
        </div>
      </div>
    </Modal>
  )
}

export default MoneyLogModal
