import { makeActionCreator } from 'lib/saga/reduxActionUtils'

const prefix = '@CORE/APP'

export const actionTypes = {
  LOADING: `${prefix}/LOADING`,
  LOADED: `${prefix}/LOADED`,
  SET_TOKEN: `${prefix}/SET_TOKEN`,
  UNSET_TOKEN: `${prefix}/UNSET_TOKEN`,
}

export const setLoaded = makeActionCreator(actionTypes.LOADED)
export const setToken = makeActionCreator(actionTypes.SET_TOKEN)
export const unSetToken = makeActionCreator(actionTypes.UNSET_TOKEN)

const initialState = {
  loaded: false,
  token: null,
}

const reducer = function (state = initialState, action) {
  // console.log(action)

  switch (action.type) {
    // 로딩
    case actionTypes.LOADING:
      return { ...state, loaded: false }
    case actionTypes.LOADED:
      return { ...state, loaded: true }
    //토큰
    case actionTypes.SET_TOKEN:
      return { ...state, token: action.payload.token }
    case actionTypes.UNSET_TOKEN:
      return { ...state, token: null }

    default:
      return state
  }
}

export default reducer
