import { call, all, put, takeLatest } from 'redux-saga/effects'
import history from 'lib/history'
import { actionTypes as accountTypes } from './store'
import { login, tokenLogin, join } from './store'
import api from './api'
import { setAuthHeader } from 'lib/request/axios'
import { setToken as setTokenAction, setLoaded } from 'modules/core/app/store'
import { setToken, removeToken, getRemember, setRemember } from 'lib/token'
import message from 'lib/message'

const { TOKEN_LOGIN, LOGIN, JOIN } = accountTypes
// 추가 에러 처리
function errorHandler(error) {}

function* loginSaga(action) {
  // console.log(action)
  yield put(login.request())
  try {
    const payload = action.payload
    // console.log(payload)
    const result = yield call(api.login, payload)
    // console.log(result)
    setToken(result.user.token)
    payload.remember && setRemember()
    setAuthHeader(result.user.token)
    yield put(login.success(result))
    yield put(setTokenAction({ token: result.user.token }))
    history.push('/')
  } catch (error) {
    message.error('인증정보가 일치하지 않습니다.')
    const failAction = login.fail({ error })
    yield call(errorHandler, failAction)
    yield put(failAction)
  }
}

function* tokenLoignSaga(action) {
  yield put(tokenLogin.request())
  try {
    const remember = getRemember()
    // console.log(remember)
    const result = yield call(api.getUser, remember)
    // console.log(result)
    // yield put(setTokenAction({ token: result.user.token }))
    // setToken(result.user.token)
    // setAuthHeader(result.user.token)
    yield put(tokenLogin.success(result))
  } catch (error) {
    // console.error(error)
    if (error && error.status === 401) {
      removeToken()
    }
    yield put(tokenLogin.fail({ error }))
    yield put(setLoaded())
  }
}

function* joinSaga(action) {
  // console.log(action)
  yield put(join.request())
  try {
    const payload = action.payload
    const result = yield call(api.join, payload)
    // console.log(result)
    yield put(join.success(result))
    yield put(setTokenAction({ token: result.user.token }))
    setToken(result.user.token)
    history.push('/')
  } catch (error) {
    // console.error(error)
    const failAction = login.fail({ error })
    yield call(errorHandler, failAction)
    yield put(failAction)
  }
}

export default function* accountSagas() {
  yield all([
    takeLatest(LOGIN.INDEX, loginSaga),
    takeLatest(TOKEN_LOGIN.INDEX, tokenLoignSaga),
    takeLatest(JOIN.INDEX, joinSaga),
  ])
}
