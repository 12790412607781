import { all } from 'redux-saga/effects'
import { sagas as coreSagas } from 'modules/core'
import storesSagas from 'modules/stores/sagas'

export default function* rootSaga() {
  yield all([coreSagas(), storesSagas()])
  // yield all([coreSagas()])
}

// How to Handle Multiple Sagas in Redux Saga https://www.youtube.com/watch?v=fEjevUGH4BU
