import React, { useState, useEffect } from 'react'
import { Form, Input, Select, Button, Space } from 'antd'

const initialSearch = {
  mobile: '',
  storeName: '',
  done: '',
}
const ReviewSearch = ({ meta, handleSearch }) => {
  const [search, setSearch] = useState(initialSearch)

  useEffect(() => {
    setSearch({ ...initialSearch, ...meta })

    return () => {
      setSearch(initialSearch)
    }
  }, [meta])

  // const handleChangeInput = (e) => {
  //   setSearch({ ...search, [e.target.name]: e.target.value })
  // }

  const handleChangeMobile = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '')
    setSearch({ ...search, mobile: value })
  }

  const handleChangeStoreName = (e) => {
    setSearch({ ...search, storeName: e.target.value })
  }

  const handleChangeDone = (value) => {
    setSearch({ ...search, done: value })
  }

  const handleSubmit = () => {
    handleSearch({
      mobile: search.mobile,
      storeName: search.storeName,
      done: search.done,
    })
  }

  const handleReset = () => {
    setSearch(initialSearch)
    handleSearch({})
  }

  return (
    <Form
      layout="inline"
      style={{ marginBottom: 16, justifyContent: 'flex-end' }}
    >
      <Form.Item>
        <Select
          name="done"
          value={search.done}
          onChange={handleChangeDone}
          style={{ width: 160 }}
        >
          <Select.Option value="">= 리뷰상태 선택 =</Select.Option>
          <Select.Option value="N">대기</Select.Option>
          <Select.Option value="Y">발송완료</Select.Option>
          <Select.Option value="C">취소</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Input
          name="mobile"
          prefix={<span style={{ marginRight: 10 }}>고객휴대폰: </span>}
          value={search.mobile}
          onChange={handleChangeMobile}
          style={{ width: 240 }}
        />
      </Form.Item>
      <Form.Item>
        <Input
          name="storeName"
          prefix={<span style={{ marginRight: 10 }}>매장명: </span>}
          value={search.storeName}
          onChange={handleChangeStoreName}
          style={{ width: 240 }}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button onClick={handleReset}>리셋</Button>
          <Button type="primary" onClick={handleSubmit}>
            검색
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default ReviewSearch
