import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { useDispatch, useSelector } from 'react-redux'
import { tokenLogin } from 'modules/core/account/store'
import { setLoaded } from '../../store'
import { getRemember } from 'lib/token'
import getCheckedToken from 'lib/request/getCheckedToken'

const token = getCheckedToken()
const remember = getRemember()

const AppLoader = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((store) => !!store.account.user)

  useEffect(() => {
    isAuthenticated && dispatch(setLoaded())
    !token && dispatch(setLoaded())
    token && !isAuthenticated && dispatch(tokenLogin({ localToken: token.toString(), remember: remember }))
  }, [isAuthenticated, dispatch])

  return null
}

export default AppLoader
