import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

const sagaMiddleware = createSagaMiddleware()
/*eslint-disable */
const composeSetup =
  process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose
/*eslint-enable */
// console.log("process.env.NODE_ENV:" + process.env.NODE_ENV);
const store = createStore(rootReducer, composeSetup(applyMiddleware(sagaMiddleware)))

// console.log("store..........");
// rootSaga 시작
sagaMiddleware.run(rootSaga)

export default store
