import React, { useEffect, useState } from 'react'
import { Table, Button, Space } from 'antd'
import ActiveStatus from 'components/Common/ActiveStatus'
import format from 'lib/format'
import { LoginOutlined, EditOutlined } from '@ant-design/icons'
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor'
import _ from 'lodash'

const AgencyList = ({
  loading,
  user,
  data,
  meta,
  handleOpen,
  handleAdd,
  handlePageChange,
  forceLogin,
  handleAgencyCeos,
  openPasswordModal,
  handleOpenCeoModal,
}) => {
  const [agencies, setAgencies] = useState([])

  useEffect(() => {
    setAgencies(data.map((agency) => ({ ...agency, isActive: false })))
  }, [data])

  const displayCeoCount = (agency) => (
    <span>
      {agency.depth - user.depth > 1 ? (
        <span style={{ padding: '4px 15px' }}>{agency.ceoCount}</span>
      ) : (
        <Button
          type="link"
          onClick={() =>
            handleAgencyCeos({
              agency: agency.id,
            })
          }
        >
          {agency.ceoCount}
        </Button>
      )}
    </span>
  )

  const defaultColumns = [
    {
      title: '대리점명',
      dataIndex: 'username',
      key: 'username',
    },
    { title: '대리점ID', dataIndex: 'loginId', key: 'loginid' },
    {
      title: '휴대폰',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (mobile) => format.phone(mobile),
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (date) => format.date(date),
    },
    {
      title: '활성',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active) => (
        <span style={{ color: active === 'Y' ? 'green' : 'red' }}>
          {active === 'Y' ? '활성' : '비활성'}
        </span>
      ),
    },
    {
      title: '매장사장님수',
      key: 'ceoCount',
      align: 'ceoCount',
      render: (text, agency) => displayCeoCount(agency),
    },
    {
      title: '관리',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (value, agency, index) => (
        <Space>
          <Button onClick={() => handleOpenCeoModal(agency)}>
            매장사장님 등록
          </Button>
          <Button danger onClick={() => handleOpen(agency)}>
            수정
          </Button>
        </Space>
      ),
    },
  ]

  // const forceLoginColumn = {
  //   title: '로그인',
  //   dataIndex: 'forceLogin',
  //   key: 'forceLogin',
  //   align: 'center',
  //   render: (value, agency, index) => (
  //     <Button type="link" onClick={() => forceLogin(agency.id)}>
  //       로그인
  //     </Button>
  //   ),
  // }

  // const columns =
  //   user.role !== 'admin'
  //     ? defaultColumns
  //     : [
  //         ...defaultColumns.slice(0, 2),
  //         forceLoginColumn,
  //         ...defaultColumns.slice(2),
  //       ]

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {user.depth < 2 && (
          <>
            <Button
              onClick={() => handleOpen()}
              type="primary"
              style={{ marginBottom: 16 }}
            >
              대리점 등록
            </Button>
          </>
        )}
      </div>
      <Table
        loading={loading}
        className="agenciesTable"
        columns={defaultColumns}
        dataSource={agencies}
        rowKey={(agency) => agency.id}
        pagination={false}
        bordered
        rowClassName={(agency) => (agency.isActive ? 'active-agency' : '')}
      />
    </div>
  )
}

export default AgencyList
