import React, { useState, useEffect } from 'react'
import { Button, Modal, Row, Col } from 'antd'
import { Form, Descriptions, Typography, Select, Switch, Card } from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  EnvironmentFilled,
} from '@ant-design/icons'
import storesApi from 'modules/stores/api'
import message from 'lib/message'
import StoreGifticon from './StoreGifticon'

const { Text, Link } = Typography

const initialGifticon = {
  gifticonCode: null,
  gifticonActive: 'N',
}

const GifticonModal = (props) => {
  const { isAdmin, modal } = props
  const { handleClose, handleExit } = props
  const { open, store } = modal

  const [inputs, setInputs] = useState(initialGifticon)
  const [loading, setLoading] = useState(false)
  const [gifticons, setGifticons] = useState([])
  const [storeGifticons, setStoreGifticons] = useState([])

  useEffect(() => {
    if (store) fetchStoreGifticons()
    return () => {
      setInputs(initialGifticon)
    }
  }, [store])

  const fetchStoreGifticons = () => {
    storesApi.fetchStoreGifticons(store).then((response) => {
      // console.log(response)
      setStoreGifticons(response.storeGifticons)
      setGifticons(response.gifticons)
    })
  }

  const handleGiftconChange = (value) => {
    setInputs({ ...inputs, gifticonCode: value })
  }

  const handleAdd = () => {
    // console.log(inputs)
    if (!inputs.gifticonCode || !store) return null
    storesApi
      .addStoreGifticon(store, inputs.gifticonCode)
      .then((response) => {
        // console.log(response)
        message.success('추가 되었습니다.')
        // setGifticons(response.gifticons)
      })
      .catch((error) => {
        // console.log(error)
        error && error.message && message.error(error.message)
      })
      .finally(() => {
        setInputs(initialGifticon)
        fetchStoreGifticons()
      })
  }

  const handleDelete = (gifticonCode) => {
    storesApi
      .deleteStoreGifticon(store, gifticonCode)
      .then((response) => {
        // console.log(response)
        message.success('삭제 되었습니다.')
        // setGifticons(response.gifticons)
      })
      .catch((error) => {
        // console.log(error)
        error && error.message && message.error(error.message)
      })
      .finally(() => {
        setInputs(initialGifticon)
        fetchStoreGifticons()
      })
  }

  const footer = [
    <Button key="cancle" onClick={handleClose} disabled={loading}>
      닫기
    </Button>,
  ]

  return (
    store &&
    gifticons && (
      <Modal
        title={store.name + ' 상품권 관리'}
        visible={open}
        afterClose={handleExit}
        onCancel={handleClose}
        footer={footer}
        maskClosable={false}
      >
        <div>
          <Form layout="horizontal">
            <Row gutter={16}>
              <Col flex="1 1 100px">
                <Form.Item>
                  <Select
                    value={inputs.gifticonCode}
                    onChange={handleGiftconChange}
                    disabled={!isAdmin}
                  >
                    <Select.Option key="ggggiff" value={null}>
                      === 상품권 선택 ===
                    </Select.Option>
                    {gifticons.map((item) => {
                      return (
                        <Select.Option
                          key={item.code}
                          value={item.code}
                          disabled={storeGifticons.find(
                            (con) => con.gifticonCode === item.code
                          )}
                        >
                          {item.name} (
                          <span style={{ color: 'blue' }}>
                            {item.discountPrice
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            원
                          </span>
                          )
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="0 1 20px">
                <Button
                  type="primary"
                  onClick={handleAdd}
                  disabled={!inputs.gifticonCode || !isAdmin}
                >
                  추가
                </Button>
              </Col>
            </Row>
          </Form>
          <div>
            {storeGifticons.map((con) => (
              <StoreGifticon
                isAdmin={isAdmin}
                gifticon={con}
                key={con.gifticonCode}
                handleDelete={handleDelete}
              />
            ))}
          </div>
        </div>
      </Modal>
    )
  )
}

export default GifticonModal
