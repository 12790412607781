import React, { useState } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { Layout, Menu, Button } from 'antd'
// import history from 'lib/history'
import logoImg from './logo.png'

import { PhoneFilled } from '@ant-design/icons'
import PasswordModal from './PasswordModal/PasswordModal'

const { SubMenu } = Menu

const { Header } = Layout

const getSubMenu = (route, user) => {
  if (!route.sidebar) return null
  if (route.role === 'admin' && user.role !== 'admin') return null
  return (
    <SubMenu key={route.path} icon={route.icon} title={route.name}>
      {route.routes.map((sub) => getMenu(sub))}
    </SubMenu>
  )
}

const getMenu = (route, user) => {
  if (!route.sidebar) return null
  if (route.role === 'admin' && user.role !== 'admin') return null
  if (route.path === '/agencies' && user.depth > 1) return null
  return (
    <Menu.Item key={route.path} icon={route.icon}>
      <Link to={route.path}>{route.name}</Link>
    </Menu.Item>
  )
}

const DefaultLayout = ({ user, children, handleLogout, routes, location }) => {
  let history = useHistory()
  const [passwordModalOpen, setPasswordModalOpen] = useState(false)

  if (!user) return <Redirect to="/login" />

  const openPasswordModal = () => {
    setPasswordModalOpen(true)
  }

  const closePasswordModal = () => {
    setPasswordModalOpen(false)
  }

  return (
    <Layout style={{ minHeight: '100vh', minWidth: 1400 }} id="default-layout">
      <Header>
        <div style={{ display: 'flex' }}>
          <div
            style={{ color: '#e6f7ff', marginRight: 20, fontWeight: 'bold' }}
          >
            리뷰왕도토리맨
          </div>
          {/* <div className="logo">
            <img src={logoImg} alt="shinhan app" style={{ height: 20 }} />
          </div> */}
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={[history.location.pathname]}
          >
            {routes.map((route) =>
              route.routes ? getSubMenu(route, user) : getMenu(route, user)
            )}
          </Menu>
          {/* <div className="helpPhone">
            <PhoneFilled /> 010-0000-0000
          </div> */}
        </div>
        <div className="account-action">
          {user.username}
          <Button
            type="link"
            onClick={() => openPasswordModal()}
            style={{ color: '#fff2f0' }}
          >
            비밀번호변경
          </Button>
          <Button
            type="link"
            onClick={handleLogout}
            style={{ color: '#fff2f0' }}
          >
            로그아웃
          </Button>
        </div>
      </Header>
      <Layout className="site-layout">{children}</Layout>
      <PasswordModal
        open={passwordModalOpen}
        handleClose={closePasswordModal}
      />
    </Layout>
  )
}
export default DefaultLayout
