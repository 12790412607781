import React from 'react'
import { Button, Row, Col, Modal } from 'antd'

import { ExclamationCircleOutlined } from '@ant-design/icons'
// function downloadImage(src) {
//   const img = new Image()
//   // img.crossOrigin = 'anonymous'
//   img.crossOrigin = 'https://api.dragondotori.co.kr'
//   img.src = src
//   img.onload = () => {
//     // create Canvas
//     const canvas = document.createElement('canvas')
//     const ctx = canvas.getContext('2d')
//     canvas.width = img.width
//     canvas.height = img.height
//     ctx.drawImage(img, 0, 0)
//     // create <a> tag
//     const a = document.createElement('a')
//     a.download = 'download.png'
//     a.href = canvas.toDataURL('image/png')
//     a.click()
//   }
// }

const StoreGifticon = (props) => {
  const { gifticon, handleDelete, isAdmin } = props

  const handleClick = () => {
    Modal.confirm({
      title: '정말로 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      content: '삭제하면 고객이 리뷰를 업로드할 수 없습니다.',
      okText: '삭제',
      cancelText: '취소',
      onOk() {
        handleDelete(gifticon.gifticonCode)
      },
    })
  }

  return (
    <div
      style={{
        padding: '10px 0',
        borderBottom: '1px solid lightgray',
        marginBottom: 10,
      }}
    >
      <Row gutter={16}>
        <Col flex="0 1 100px">
          <img
            alt=""
            src={process.env.REACT_APP_API_URL + gifticon.qrPath}
            style={{ width: 100 }}
          />
        </Col>
        <Col flex="1 1 200px">
          <div style={{ fontWeight: 'bold' }}>{gifticon.gifticonName}</div>
          <div>
            {gifticon.gifticonPrice
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            원
          </div>
          <div style={{ fontSize: 10 }}>
            <a href={gifticon.qrUrl} rel="noreferrer noopener" target="_blank">
              {gifticon.qrUrl}
            </a>
          </div>
          <div>
            {gifticon.qrShortUrl}{' '}
            {isAdmin && (
              <span style={{ float: 'right' }}>
                <Button danger onClick={handleClick}>
                  삭제
                </Button>
              </span>
            )}{' '}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default StoreGifticon
