import request from 'lib/request'

const api = {
  fetch: (meta) => request.get(`/api/reviews`, meta),
  sendGifticon: (review) => request.post(`/api/reviews/${review.id}/send`),
  resendGifticon: (review) => request.post(`/api/reviews/${review.id}/resend`),
  cancelGifticon: (review) => request.post(`/api/reviews/${review.id}/cancel`),
  addMoneyLog: (review, direct_money) =>
    request.post(`/api/reviews/${review.id}/money_log/add`, { direct_money }),
  deleteMoneyLog: (review) =>
    request.post(`/api/reviews/${review.id}/money_log/delete`),
}

export default api
