import axios from './axios'
import errorHandler from './requestErrorHandler'

const dataHandler = (res) => res.data

const getRequest = (url, data) => axios({ method: 'get', url, params: data }).then(dataHandler).catch(errorHandler)
const postRequest = (url, data) => axios({ method: 'post', url, data }).then(dataHandler).catch(errorHandler)
const putRequest = (url, data) => axios({ method: 'put', url, data }).then(dataHandler).catch(errorHandler)
const deleteRequest = (url) => axios({ method: 'delete', url }).then(dataHandler).catch(errorHandler)
const jsonRequest = (url, data) =>
  axios({ method: 'post', url, data: data, headers: { 'Content-Type': 'application/json' } })
    .then(dataHandler)
    .catch(errorHandler)
const multipartRequest = (url, data) =>
  axios({ method: 'post', url, data, headers: { 'Content-Type': 'multipart/form-data' } })
    .then(dataHandler)
    .catch(errorHandler)

const request = {
  get: getRequest,
  post: postRequest,
  put: putRequest,
  del: deleteRequest,
  multi: multipartRequest,
  json: jsonRequest,
}

export default request
