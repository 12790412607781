function ApiException(error) {
  this.errors = error.errors
  this.status = error.status
  this.message = error.message
}

const requestErrorHandler = (error) => {
  // 정상적인 서버에러
  if (error.response && error.response.data) {
    // console.log(error.response.data)
    let message
    if (error.response.status === 500) {
      message = '서버에러 입니다.'
    } else {
      message = error.response.data.message
        ? error.response.data.message
        : error.response.data.errors &&
          error.response.data.errors.default &&
          error.response.data.errors.default[0]
    }

    const errorData = {
      errors: error.response.data.errors,
      status: error.response.status,
      message: message,
    }
    throw new ApiException(errorData)
  } else if (error.request) {
    const errorData = {
      errors: {},
      status: 800,
      message: '접속에러가 발생했습니다(8).',
    }
    throw new ApiException(errorData)
  } else {
    const errorData = {
      errors: {},
      status: 900,
      message: '접속에러가 발생했습니다(9).',
    }
    throw new ApiException(errorData)
  }
}

export default requestErrorHandler
