import React, { useEffect } from 'react'
import { Button } from 'antd'

const StoreServicePolicy = (props) => {
  useEffect(() => {
    const htmlTitle = document.querySelector('title')
    htmlTitle.innerText = '서비스 약관'
  }, [])

  return (
    <div>
      <Button>StoreServicePolicy</Button>
    </div>
  )
}

export default StoreServicePolicy
