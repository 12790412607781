import jwt from 'jsonwebtoken'
import { getToken, removeToken } from 'lib/token'

const getCheckedToken = () => {
  let token = getToken()
  // 토큰이 유효한지 체크
  if (token) {
    const decoded = jwt.decode(token)
    const remains = decoded && decoded.exp ? decoded.exp - Math.round(new Date().getTime() / 1000) : 0
    if (remains < 1) {
      removeToken()
      return null
    }
  }

  return token
}

export default getCheckedToken
