// replace 1. Ceos => Stores
// replace 2.  CEOS => STORES
// replace 3. ceos => stores
import {
  makeAsyncActionsTypes,
  makeActionCreator,
  makeAsyncActionCreator,
} from 'lib/saga/reduxActionUtils'
import produce from 'immer'
/************************************************************************
 // 1. action types
 
 FETCH_CEOS.INDEX
 FETCH_CEOS.REQUEST
 FETCH_CEOS.SUCCESS
 FETCH_CEOS.FAIL
 NORMAL_ACTION
 ************************************************************************/
const prefix = '@APP/CEOS'

export const actionTypes = {
  FETCH_CEOS: makeAsyncActionsTypes(prefix, 'FETCH_CEOS'),
  FETCH_ONE_CEOS: makeAsyncActionsTypes(prefix, 'FETCH_ONE_CEOS'),
  CREATE_CEOS: makeAsyncActionsTypes(prefix, 'CREATE_CEOS'),
  UPDATE_CEOS: makeAsyncActionsTypes(prefix, 'UPDATE_CEOS'),
  DELETE_CEOS: makeAsyncActionsTypes(prefix, 'DELETE_CEOS'),
  CLOSE_MODAL: `${prefix}/CLOSE_MODAL`,
  OPEN_MODAL: `${prefix}/OPEN_MODAL`,
  REMOVE_ERROR: `${prefix}/REMOVE_ERROR`,

  UPDATE_CASH: makeAsyncActionsTypes(prefix, 'UPDATE_CASH'),
  CLOSE_CASH_MODAL: `${prefix}/CLOSE_CASH_MODAL`,
  OPEN_CASH_MODAL: `${prefix}/OPEN_CASH_MODAL`,
}

/************************************************************************
// 2. action creator

fetchCeos(payload)
fetchCeos.request(payload)
fetchCeos.success(payload)
fetchCeos.fail(payload)
resetUser(payload)
************************************************************************/
export const fetchCeos = makeAsyncActionCreator(actionTypes.FETCH_CEOS)
export const fetchOneCeos = makeAsyncActionCreator(actionTypes.FETCH_ONE_CEOS)
export const createCeos = makeAsyncActionCreator(actionTypes.CREATE_CEOS)
export const updateCeos = makeAsyncActionCreator(actionTypes.UPDATE_CEOS)
export const deleteCeos = makeAsyncActionCreator(actionTypes.DELETE_CEOS)

export const closeModal = makeActionCreator(actionTypes.CLOSE_MODAL)
export const openModal = makeActionCreator(actionTypes.OPEN_MODAL)
export const removeError = makeActionCreator(actionTypes.REMOVE_ERROR)

export const updateCash = makeAsyncActionCreator(actionTypes.UPDATE_CASH)
export const closeCashModal = makeActionCreator(actionTypes.CLOSE_CASH_MODAL)
export const openCashModal = makeActionCreator(actionTypes.OPEN_CASH_MODAL)
/***********************************************************************
// 3. reducer
error format
{
  status: 422,
  message: "입력값을 확인해주세요.",
  errors: [
    email: [{...}]
  ]
}

************************************************************************/
const initialModalState = {
  open: false,
  requesting: false,
  error: null,
  ceo: null,
}

const initialCashModalState = {
  open: false,
  requesting: false,
  error: null,
  ceo: null,
  file: null,
}

const initialState = {
  loading: false,
  error: null,
  ceos: [],
  search: {},
  pagination: {},
  modal: initialModalState,
  cashModal: initialCashModalState,
}

const ceosReducer = function (state = initialState, action) {
  switch (action.type) {
    // FETCH_CEOS
    case actionTypes.FETCH_CEOS.INDEX:
    case actionTypes.FETCH_CEOS.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true
      })
    case actionTypes.FETCH_CEOS.SUCCESS:
      return {
        ...state,
        ceos: [...action.payload.ceos.data],
        pagination: {
          total: action.payload.ceos.total,
          per_page: action.payload.ceos.per_page,
          current_page: action.payload.ceos.current_page,
        },
        search: action.payload.ceos.search,
        loading: false,
      }
    case actionTypes.FETCH_CEOS.FAIL:
      return { ...state, loading: false, error: action.payload.error }

    // FETCH_ONE_CEOS
    case actionTypes.FETCH_ONE_CEOS.INDEX:
    case actionTypes.FETCH_ONE_CEOS.REQUEST:
      return { ...state, modal: { ...state.modal, requesting: true } }
    case actionTypes.FETCH_ONE_CEOS.SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          requesting: false,
          ceo: action.payload.ceo,
        },
      }
    case actionTypes.FETCH_ONE_CEOS.FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          requesting: false,
          error: action.payload.error,
        },
      }

    // CREATE_CEOS
    case actionTypes.CREATE_CEOS.INDEX:
    case actionTypes.CREATE_CEOS.REQUEST:
      return {
        ...state,
        modal: { ...state.modal, open: true, requesting: true },
      }
    case actionTypes.CREATE_CEOS.SUCCESS:
      return { ...state, modal: { ...state.modal, requesting: false } }
    case actionTypes.CREATE_CEOS.FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          requesting: false,
          error: action.payload.error,
        },
      }

    // UPDATE_CEOS
    case actionTypes.UPDATE_CEOS.INDEX:
    case actionTypes.UPDATE_CEOS.REQUEST:
      return { ...state, modal: { ...state.modal, loading: true } }
    case actionTypes.UPDATE_CEOS.SUCCESS:
      return { ...state, modal: { ...state.modal, loading: false }, ceo: {} }
    case actionTypes.UPDATE_CEOS.FAIL:
      return {
        ...state,
        modal: { ...state.modal, loading: false, error: action.payload.error },
      }

    // DELETE_CEOS
    case actionTypes.DELETE_CEOS.INDEX:
    case actionTypes.DELETE_CEOS.REQUEST:
      return { ...state, modal: { ...state.modal, loading: true } }
    case actionTypes.DELETE_CEOS.SUCCESS:
      return { ...state, modal: { ...state.modal, loading: false } }
    case actionTypes.DELETE_CEOS.FAIL:
      return {
        ...state,
        modal: { ...state.modal, loading: false, error: action.payload.error },
      }

    // OPEN_MODAL
    case actionTypes.OPEN_MODAL:
      return {
        ...state,
        modal: { ...state.modal, open: true, agencyId: action.payload },
      }

    // CLOSE_MODAL
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        modal: initialModalState,
      }

    case actionTypes.REMOVE_ERROR:
      return produce(state, (draft) => {
        draft.modal.error.errors[action.payload.name] = undefined
      })
    default:
      return state

    /**************************************************************************************************
      충전금 모달
    **************************************************************************************************/
    // UPDATE_CASH
    case actionTypes.UPDATE_CASH.INDEX:
    case actionTypes.UPDATE_CASH.REQUEST:
      return { ...state, cashModal: { ...state.cashModal, loading: true } }
    case actionTypes.UPDATE_CASH.SUCCESS:
      return {
        ...state,
        cashModal: { ...state.cashModal, loading: false },
        store: {},
      }
    case actionTypes.UPDATE_CASH.FAIL:
      return {
        ...state,
        cashModal: {
          ...state.cashModal,
          loading: false,
          error: action.payload.error,
        },
      }
    // OPEN_CASH_MODAL
    case actionTypes.OPEN_CASH_MODAL:
      return {
        ...state,
        cashModal: {
          ...state.cashModal,
          open: true,
          ceo: action.payload.ceo,
        },
      }
    // CLOSE_CASH_MODAL
    case actionTypes.CLOSE_CASH_MODAL:
      return { ...state, cashModal: initialCashModalState }
  }
}

export default ceosReducer
